import axios from 'axios';
import React from 'react'
import ReactDOM from 'react-dom/client';
import IndexReadingComponents from './ReadingComponents/IndexReadingComponents';
import ReadingDisplayComponents from './ReadingComponents/ReadingDisplayComponents';
import { ProgressSpinner } from 'primereact/progressspinner';
const MasterMeterReading = () => {
    const[loadingBorehole,setLoadingBorehole]=React.useState(true);
    const[boreholeData,setBoreholeData]=React.useState([]);
    const[offtakeData,setOfftakeData]=React.useState([]);
    const[treatmentData,setTreatmentData]=React.useState([])
    const[boreholeReadingData,setBoreholeReadingData]=React.useState([])
    const[boreholeReadingLoading,setBoreholeReadingLoading]=React.useState(false)
    const[offtakeReadingData,setOfftakeReadingData]=React.useState([])
    const[offtakeReadingLoading,setOfftakeReadingLoading]=React.useState(false)
    const[treatmentReadingData,setTreatmentReadingData]=React.useState([])
    const[treatmentReadingLoading,setTreatmentReadingLoading]=React.useState(false)
    const getAllBoreholesData=async()=>{
        setLoadingBorehole(true)
        await axios.get('getAllBoreholesData')
        .then(function (response) {
            if(response?.data.hasOwnProperty('boreholeData')){
                setBoreholeData(response?.data?.boreholeData)
                setLoadingBorehole(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const getAllOfftakeData=async()=>{
        setLoadingBorehole(true)
        await axios.get('getAllOfftakeData')
        .then(function (response) {
            if(response?.data.hasOwnProperty('offtakeData')){
                setOfftakeData(response?.data?.offtakeData)
                setLoadingBorehole(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const getAllTreatment=async()=>{
        setLoadingBorehole(true)
        await axios.get('getAllTreatment')
        .then(function (response) {
            if(response?.data.hasOwnProperty('Treatment')){
                setTreatmentData(response?.data?.Treatment)
                setLoadingBorehole(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    React.useEffect(()=>{
        getAllBoreholesData();
        getAllOfftakeData();
        getAllTreatment();
    },[])

    const getBoreholeReadings=async()=>{
        //
        setBoreholeReadingLoading(true)
        await axios.get('getallboreholedata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('boreholeReading')){
                setBoreholeReadingData(response?.data?.boreholeReading)
                setBoreholeReadingLoading(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }

    const getOfftakeReadings=async()=>{
        //
        setOfftakeReadingLoading(true)
        await axios.get('getallofftakedata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('offtakeReading')){
                setOfftakeReadingData(response?.data?.offtakeReading)
            }
            setOfftakeReadingLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const getTreatment=async()=>{
        setTreatmentReadingLoading(true)
        await axios.get('getalltreatmentdata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('treatmentData')){
                setTreatmentReadingData(response?.data?.treatmentData)
            }
            setTreatmentReadingLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    React.useEffect(()=>{
        getOfftakeReadings();
        getBoreholeReadings();
        getTreatment();
    },[])
  return (
      <div className="p-panel-content">
          <div className="row">
            {loadingBorehole ?
             <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="2" fill="var(--surface-ground)" /> :
                <IndexReadingComponents treatmentData={treatmentData} boreholeData={boreholeData} offtakeData={offtakeData}/>
            }
          <ReadingDisplayComponents
            boreholeReadingData={boreholeReadingData}
            boreholeReadingLoading={boreholeReadingLoading}
            setBoreholeReadingLoading={setBoreholeReadingLoading}
            setBoreholeReadingData={setBoreholeReadingData}
            offtakeReadingData={offtakeReadingData}
            offtakeReadingLoading={offtakeReadingLoading}
            setOfftakeReadingData={setOfftakeReadingData}
            setOfftakeReadingLoading={setOfftakeReadingLoading}
            treatmentReadingData={treatmentReadingData} setTreatmentReadingData={setTreatmentReadingData}
            treatmentReadingLoading={treatmentReadingLoading} setTreatmentReadingLoading={setTreatmentReadingLoading}
           />
          </div>
      </div>
  );
}

export default MasterMeterReading
if (document.getElementById('master-meter-reading')) {
    const Index = ReactDOM.createRoot(document.getElementById("master-meter-reading"));
    Index.render(
        <React.StrictMode>
            <MasterMeterReading/>
        </React.StrictMode>
    )
}
