import React from 'react'

const OfftakeReadingTable = ({offtakeReadingData}) => {
    React.useEffect(()=>{
        $(document).ready(function () {
            $('#offtake_id').DataTable();
        });
    },[])
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 table-responsive bg-white">
    <table id='offtake_id' className="p-datatable table table-striped table-bordered">
        <thead>
            <tr>
                <th className="text-nowrap">Reading Date</th>
                <th className="text-nowrap">Offtake</th>
                <th className="text-nowrap">Water P.R</th>
                <th className="text-nowrap">Water C.R</th>
                <th className="text-nowrap">Cons (M<sup>3</sup>)</th>
                <th className="text-nowrap">Comments</th>
                <th className="text-nowrap">Read By</th>
            </tr>
        </thead>
        <tbody>
            {offtakeReadingData.map((data)=>(
                <tr key={data.offreadId}>
                    <td className="text-center">{ data.off_reading_at }</td>
                    <td className="text-center">{ data.offtake_name }</td>
                    <td className="text-center">{ data.off_previous_water_reading }</td>
                    <td className="text-center">{ data.off_water_reading }</td>
                    <td className="text-center">{ data.off_water_consumed }</td>
                    <td className="text-center">{ data.off_remarks }</td>
                    <td className="text-center">{ data.off_read_by }</td>
                </tr>
             ))}
        </tbody>
    </table>
</div>
  )
}

export default OfftakeReadingTable
