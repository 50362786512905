import React from 'react'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
const TreatmentReadingForm = ({treatmentData}) => {
    const toast = React.useRef(null);
    const showError = (msg) => {
        toast.current.show({ severity:'error', summary: 'Error', detail: msg });
    };
    const showSuccess = (msg) => {
        toast.current.show({ severity:'success', summary: 'Success', detail: msg });
    };
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const [treat_borehole_id, setTreat_borehole_id] = React.useState("");
    const [treat_water_reading, setTreat_water_reading] = React.useState("");
    const [treat_water_image, setTreat_water_image] = React.useState("");
    const [treat_power_reading, setTreat_power_reading] = React.useState("");
    const [treat_power_image, setTreat_power_image] = React.useState("");
    const [alum, setAlum] = React.useState("");
    const [soda, setSoda] = React.useState("");
    const [hth, setHth] = React.useState("");
    const [treat_reading_at, setTreat_reading_at] = React.useState("");
    const [treat_remarks, setTreat_remarks] = React.useState("");
    const[savingLoading,setSavingLoading]=React.useState(false);
    const saveTreatmentData=async()=>{
        const formdatas=new FormData();
        formdatas.append('power_image',treat_power_image)
        formdatas.append('water_image',treat_water_image)
        formdatas.append('properties', JSON.stringify({
              powerreading:treat_power_reading,
              waterreading:treat_water_reading,
              readingDate:treat_reading_at,
              readingRemarks:treat_remarks,
              selectedTreatment:treat_borehole_id,
              alumReading:alum,
              sodaReading:soda,
              chlorineReading:hth
            }));
            setSavingLoading(true)
            await axios('saveTreatmentData',{
                method:'post',
                data: formdatas,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data',
                },
            }).then(function (response) {
                    if(response.data.hasOwnProperty('errors')){
                        if(response.data.errors?.selectedTreatment){
                            showInfo(response.data.errors?.selectedTreatment[0])
                            return false
                        }if(response.data.errors?.power_image){
                            showInfo(response.data.errors?.power_image[0])
                            return false
                        } if(response.data.errors?.powerreading){
                            showInfo(response.data.errors?.powerreading[0])
                            return false
                        }if(response.data.errors?.water_image){
                            showInfo(response.data.errors?.water_image[0])
                            return false
                        }if(response.data.errors?.waterreading){
                            showInfo(response.data.errors?.waterreading[0])
                            return false
                        }
                    }
                    if (response?.data.hasOwnProperty('cycleError')) {
                        showError(response?.data?.cycleError);
                    }
                   if(response.data.hasOwnProperty('pastDateNtification')){
                       showError(response.data?.pastDateNtification);
                   }if(response.data.hasOwnProperty('success')){
                       showSuccess(response.data?.success)
                        setTreat_borehole_id('')
                        setTreat_water_reading('')
                        setTreat_water_image('')
                        setTreat_power_reading('')
                        setTreat_power_image('')
                        setAlum('')
                        setSoda('')
                        setHth('')
                        setTreat_reading_at('')
                        setTreat_remarks('')
                   }
                   if(response.data.hasOwnProperty('ErrorSubmission')){
                     showError(response.data?.ErrorSubmission)
                   }
                   if (response?.data.hasOwnProperty('imagesError')) {
                    showInfo(response?.data?.imagesError);
                   }
                   if(response.data.hasOwnProperty('recordExistence')){
                     showError(response.data?.recordExistence)
                   }//
                   setSavingLoading(false)
            }).catch(function (error) {
               console.log(error.response)
               setSavingLoading(false)
            })
            .then(function () {
            })
    }

  return (
    <div
    style={{
        overflowY: "scroll",
        maxHeight: 530,
        padding: 10,
        fontSize: 13,
        fontFamily: ':"Times New Roman", Times, serif',
    }}
>
<Toast ref={toast} />
    <div className="mb-3">
        <label htmlFor="treat_borehole_id" className="form-label">
            Select Treatment
        </label>
        <select
            className="form-select col-md-12 col-sm-12 "
            name="treat_borehole_id"
            aria-label="Default select example"
            value={treat_borehole_id}
            onChange={(e)=>setTreat_borehole_id(e.target.value)}
        >
            <option value>Select Treatment</option>
            {treatmentData.map((data)=>(
                <option key={data.id} value={data.id}>{data.treatment_name}</option>
            ))}
        </select>
    </div>
    <div className="mb-3">
        <label htmlFor="treat_water_reading" className="form-label">
            Water Reading
        </label>
        <input
            type="text"
            name="treat_water_reading"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            value={treat_water_reading}
            onChange={(e)=>setTreat_water_reading(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="treat_water_image" className="form-label">
            Water reading picture
        </label>
        <input
            type="file"
            name="treat_water_image"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            onChange={(e)=>setTreat_water_image(e.target.files[0])}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="treat_power_reading" className="form-label">
            Power reading
        </label>
        <input
            type="text"
            name="treat_power_reading"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            value={treat_power_reading}
            onChange={(e)=>setTreat_power_reading(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="treat_power_image" className="form-label">
            Power reading picture
        </label>
        <input
            type="file"
            name="treat_power_image"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            onChange={(e)=>setTreat_power_image(e.target.files[0])}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="alum" className="form-label">
            Alum Used (Kgs)
        </label>
        <input
            type="text"
            name="alum"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            value={alum}
            onChange={(e)=>setAlum(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="soda" className="form-label">
            Soda Used (Kgs)
        </label>
        <input
            type="text"
            name="soda"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            value={soda}
            onChange={(e)=>setSoda(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="hth" className="form-label">
            HTH (Kgs)
        </label>
        <input
            type="text"
            name="hth"
            className="p-inputtext col-md-12 col-sm-12 form-control"
            value={hth}
            onChange={(e)=>setHth(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <label htmlFor="treat_reading_at" className="form-label">
            Reading Date
        </label>
        <input
            type="date"
            name="treat_reading_at"
            value={treat_reading_at}
            onChange={(e)=>setTreat_reading_at(e.target.value)}
            className="p-datepicker-calendar p-inputtext col-md-12 col-sm-12 form-control"
        />
    </div>
    <div className="mb-3">
        <label htmlFor="remarks" className="form-label">
            Comment
        </label>
        <textarea
            className="p-inputtext col-md-12 col-sm-12"
            name="treat_remarks"
            id="exampleFormControlTextarea1"
            rows={3}
            value={treat_remarks}
            onChange={(e)=>setTreat_remarks(e.target.value)}
        />
    </div>
    <div className="mb-3">
        <Button label="Save Data" loading={savingLoading} raised onClick={()=>saveTreatmentData()} severity="help" icon="pi pi-save" />
    </div>
</div>
  )
}

export default TreatmentReadingForm
