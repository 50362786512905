import React from 'react'
const BoreholeReadingTable = ({boreholeReadingData}) => {
    React.useEffect(()=>{
        $(document).ready(function () {
            $('#borehole_table').DataTable();
        })
    },[])
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 table-responsive bg-white">
    <table id='borehole_table' className="p-datatable table table-striped table-bordered">
        <thead>
            <tr>
                <th className="text-nowrap">Reading Date</th>
                <th className="text-nowrap">Borehole</th>
                <th className="text-nowrap">Water P.R</th>
                <th className="text-nowrap">Water C.R</th>
                <th className="text-nowrap">cons (M<sup>3</sup>)</th>
                <th className="text-nowrap">Power P.R</th>
                <th className="text-nowrap">Power C.R</th>
                <th className="text-nowrap">Usage(kWh)</th>
                <th className="text-nowrap">Comments</th>
                <th className="text-nowrap">Read By</th>
            </tr>
        </thead>
        <tbody>
            {boreholeReadingData.map((data)=>(
               <tr key={data.bhreadId}>
                    <td className="text-center">{ data.reading_at }</td>
                    <td className="text-center text-nowrap">{ data.borehole_name }</td>
                    <td className="text-center">{ data.previous_water_reading }</td>
                    <td className="text-center">{ data.water_reading }</td>
                    <td className="text-center">{ data.water_consumed }</td>
                    <td className="text-center">{ data.previous_power_reading }</td>
                    <td className="text-center">{ data.power_reading }</td>
                    <td className="text-center">{ data.power_consumed }</td>
                    <td className="text-center">{ data.remarks }</td>
                    <td className="text-center">{ data.read_by }</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>

  )
}

export default BoreholeReadingTable

