import React from 'react'

const TreatmentReadingTable = ({treatmentReadingData}) => {
    React.useEffect(()=>{
        $(document).ready(function () {
            $('#treatment_table').DataTable();
        });
    },[])
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 table-responsive bg-white">
    <table id='treatment_table' className="p-datatable table table-striped table-bordered">
        <thead>
            <tr>
                <th className="text-nowrap">Reading Date</th>
                <th className="text-nowrap">Treatment</th>
                <th className="text-nowrap">Water P.R </th>
                <th className="text-nowrap">Water C.R</th>
                <th className="text-nowrap">Cons. (M<sup>3</sup>)</th>
                <th className="text-nowrap">Power P.R</th>
                <th className="text-nowrap">Power C.R</th>
                <th className="text-nowrap">Usage(kWh)</th>
                <th className="text-nowrap">Alum (kgs)</th>
                <th className="text-nowrap">Soda (kgs)</th>
                <th className="text-nowrap">HTH (kgs)</th>
                <th className="text-nowrap">Comments</th>
                <th className="text-nowrap">Read By</th>
            </tr>
        </thead>
        <tbody>
            {treatmentReadingData.map((data)=>(
                <tr key={data.treatId}>
                    <td className="text-center">{ data.treat_reading_at }</td>
                    <td className="text-center text-nowrap">{ data.treatment_name }</td>
                    <td className="text-center">{ data.previous_water_reading }</td>
                    <td className="text-center">{ data.treat_water_reading }</td>
                    <td className="text-center">{ data.water_consumed}</td>
                    <td className="text-center">{ data.previous_power_reading }</td>
                    <td className="text-center">{ data.treat_power_reading }</td>
                    <td className="text-center">{ data.power_consumed }</td>
                    <td className="text-center">{ data.alum }</td>
                    <td className="text-center">{ data.soda }</td>
                    <td className="text-center">{ data.hth }</td>
                    <td className="text-center">{ data.treat_remarks }</td>
                    <td className="text-center">{ data.read_by }</td>
                </tr>
            ))}

        </tbody>
    </table>
</div>

  )
}

export default TreatmentReadingTable
