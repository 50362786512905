import React from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
const TreatmentComponents = () => {
  return (
    <Card style={{ fontSize:13,fontFamily:'times' }}>
    <h6 className='text-center'>Register Treatment</h6>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Water Meter Serial</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">lnitial Reading</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Power Meter Serial</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Power Initial</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Treatment name</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Treatment Scheme</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Treatment Zone</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <Button label="Save Data" severity="help" />
    </div>
</Card>
  )
}

export default TreatmentComponents
