import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import OfftakeVerificationModal from './OfftakeVerificationModal';
const OfftakeVerification = () => {
    const toast = React.useRef(null);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
   const[offtakeVerificationData,setOfftakeVerificationData]=React.useState([])
   const[verificationDataLoading,setVerificationDataLoading]=React.useState(true)
   const [verifyReading,setVerifyReading]=React.useState('')
   const[verificationData,setVerificationData]=React.useState({})
   const[loading,setLoading]=React.useState(false)
   const[showDataModal,setShowDataModal]=React.useState(false)
   const[reloadData,setReloadData]=React.useState(false)

   const getVerificationData=async()=>{
    setVerificationDataLoading(true)
    await axios.get('GetOfftakeunverified')
    .then(function (response) {
        if(response?.data.hasOwnProperty('offtakeReading')){
            setOfftakeVerificationData(response?.data?.offtakeReading)
            setVerificationDataLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error.response.message);
      });
    }
    React.useEffect(()=>{
        getVerificationData();
    },[])


    React.useEffect(()=>{
        if(reloadData===true){
            getVerificationData();
        }
    },[reloadData])

    const getBoreholeToVerify=async(id)=>{
        setLoading(true)
        await axios.get('GetVerificationOfftakesReading/'+id)
        .then(function (response) {
            if(response.data.hasOwnProperty('OfftakesReading')){
                setVerificationData(response?.data?.OfftakesReading)
            };
            setLoading(false)
            setShowDataModal(true);
          })
          .catch(function (error) {
            showInfo('No access rights to execute the operation');
            setLoading(false)
          });
          setReloadData(false);
    }
    React.useEffect(()=>{
        if(verifyReading !==''){
            getBoreholeToVerify(verifyReading);
        }
    },[verifyReading])

    //verifyOfftakeReading
  return (
    <div className="col-md-12 col-sm-12 col-lg-12">
    <Toast ref={toast} />
    {verificationDataLoading ?
        <ProgressSpinner
        style={{ width: "50px", height: "50px" }}
        strokeWidth="2"
        fill="var(--surface-ground)"/> :
        <div className="bg-white table-responsive">
        <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Offtake name</th>
                    <th>reading date</th>
                    <th>Water C.R</th>
                    <th>Water P.R</th>
                    <th>
                        CONS (M<sup>3</sup>)
                    </th>
                    <th>Verified</th>
                </tr>
            </thead>
            <tbody>
                {offtakeVerificationData.map((data) => (
                    <tr key={data.offreadId}>
                        <td>{data.offtake_name}</td>
                        <td>{data.off_reading_at}</td>
                        <td>{data.off_water_reading}</td>
                        <td>{data.off_previous_water_reading}</td>
                        <td>{data.off_water_consumed}</td>
                        <td>
                            {loading && verifyReading == data.offreadId ? (
                                <div
                                    className="spinner-border text-danger spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                <>
                                    {(verifyReading == data.offreadId) ? (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='verification'
                                            value={data.offreadId}
                                            onChange={(e) =>{
                                            setVerifyReading(
                                                e.target.value
                                            )
                                            }
                                            }
                                            defaultChecked
                                        />
                                    ) : (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='verification'
                                            value={data.offreadId}
                                            onChange={(e) =>{
                                            setVerifyReading(
                                                e.target.value
                                            )
                                            }
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
    }
    <OfftakeVerificationModal
        showDataModal={showDataModal}
        setShowDataModal={setShowDataModal}
        verificationData={verificationData}
        setReloadData={setReloadData}
    />
    </div>
  )
}

export default OfftakeVerification
