import React from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
const OfftakeComponents = () => {
  return (
    <Card style={{ fontSize:13,fontFamily:'times' }}>
    <h6 className='text-center'>Register Offtake</h6>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Water Meter Serial</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">lnitial Reading</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Offtake name</label>
            <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Offtake Scheme</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Offtake Zone</label>
        <input type="text" className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
            <Button label="Save Data" severity="help" />
    </div>
    </Card>
  )
}

export default OfftakeComponents
