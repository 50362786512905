import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Toast } from 'primereact/toast';
const VerificationModal = ({verificationData,showDataModal,setShowDataModal,setReloadData}) => {
    const toast = React.useRef(null);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const showError = (msg) => {
        toast.current.show({ severity:'error', summary: 'Error', detail: msg });
    };
    const showSuccess = (msg) => {
        toast.current.show({ severity:'success', summary: 'Success', detail: msg });
    };
    const[editId,setEditId]=React.useState('')
    const[editWaterCR,setEditWaterCR]=React.useState('')
    const[editWaterPR,setEditWaterPR]=React.useState('')
    const[editPowerCR,setEditPowerCR]=React.useState('')
    const[editPowerPR,setEditPowerPR]=React.useState('')
    const[loading,setLoading]=React.useState(false)
    React.useEffect(()=>{
        if(showDataModal===true){
            setEditId(verificationData.id)
            setEditWaterCR(verificationData.water_reading)
            setEditWaterPR(verificationData.previous_water_reading)
            setEditPowerCR(verificationData.power_reading)
            setEditPowerPR(verificationData.previous_power_reading)
        }
    },[showDataModal])

    const saveEdits=async()=>{
        setLoading(true)
        await axios.post('saveEditBoreholeReading',{
            editId:editId,
            editWaterCR:editWaterCR,
            editWaterPR:editWaterPR,
            editPowerCR:editPowerCR,
            editPowerPR:editPowerPR
        })
        .then(function (response) {

            if(response.data.hasOwnProperty('success')){
                showSuccess(response.data?.success)
                setShowDataModal(false)
                setReloadData(true)
            }
            if(response.data.hasOwnProperty('failed')){
                showError(response.data?.failed)
            }
            setLoading(false)
          })
          .catch(function (error) {
            console.log(error)
            setLoading(false)
          });
    }
  return (
    <Dialog  position='top' visible={showDataModal} style={{ width: '60vw' }} onHide={() => setShowDataModal(false)}>
        <Toast ref={toast} />
        <div className="row" style={{padding:10,fontSize:13,fontFamily:'Times New Roman'}}>
            <div className="col-md-6">
                <table className='table table-bordered bg-white'>
                    <tbody>
                        <tr>
                            <td >Water Image</td>
                            <td className='text-body-secondary text-center'><Image src={'DMA-READING-IMAGES/WATER/'+verificationData.water_image} alt="Image" width="60" height='50' preview /></td>
                        </tr>
                        <tr>
                            <td>Power Image</td>
                            <td  className='text-center'><Image src={'DMA-READING-IMAGES/POWER/'+verificationData.power_image} alt="Image" width="60"  height='50' preview /></td>
                        </tr>
                        <tr >
                            <td>Remarks</td>
                            <td>{verificationData.remarks}</td>
                        </tr>
                        <tr >
                            <td>Ready Verification</td>
                            <td>{verificationData.reading_verified}</td>
                        </tr>
                        <tr>
                            <td>Ready By</td>
                            <td>{verificationData.read_by}</td>
                        </tr>
                        <tr>
                            <td>Ready At</td>
                            <td>{verificationData.reading_at}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="water_reading" className="form-label">
                      Water C.R
                  </label>
                  <input
                      type="text"
                      name="water_reading"
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                      value={editWaterCR}
                      onChange={(e)=>setEditWaterCR(e.target.value)}
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="water_reading" className="form-label">
                      Water P.R
                  </label>
                  <input
                      type="text"
                      name="water_reading"
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                      value={editWaterPR}
                      onChange={(e)=>setEditWaterPR(e.target.value)}
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="water_reading" className="form-label">
                      Power C.R
                  </label>
                  <input
                      type="text"
                      name="water_reading"
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                      value={editPowerCR}
                      onChange={(e)=>setEditPowerCR(e.target.value)}
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="water_reading" className="form-label">
                      Power P.R
                  </label>
                  <input
                      type="text"
                      name="water_reading"
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                      value={editPowerPR}
                      onChange={(e)=>setEditPowerPR(e.target.value)}
                  />
              </div>
              <div className="mb-3">
                    <Button loading={loading} label="Save Data" onClick={()=>saveEdits()} raised  severity="info" icon="pi pi-save" />
                </div>
            </div>
        </div>
    </Dialog>
  )
}

export default VerificationModal
