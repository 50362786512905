import React from 'react'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
const OfftakeReadingForm = ({offtakeData}) => {
    const toast = React.useRef(null);
    const[offtake_id,setOfftake_id]=React.useState('')
    const[off_water_reading,setOff_water_reading]=React.useState('')
    const[off_reading_at,setOff_reading_at]=React.useState('')
    const[remarks,setRemarks]=React.useState('')
    const[off_water_image,setOff_water_image]=React.useState('')
    const[savingLoading,setSavingLoading]=React.useState(false);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const showError = (msg) => {
        toast.current.show({ severity:'error', summary: 'Error', detail: msg });
    };
    const showSuccess = (msg) => {
        toast.current.show({ severity:'success', summary: 'Success', detail: msg });
    };
    const saveOfftake=async()=>{
        const formdatas=new FormData();
        formdatas.append('water_image',off_water_image)
        formdatas.append('properties',JSON.stringify({
            waterreading:off_water_reading,
            readingDate:off_reading_at,
            readingRemarks:remarks,
            selectedOfftake:offtake_id,
          }));
          //
          setSavingLoading(true)
          await axios('saveOfftakeReadings',{
            method:'post',
            data: formdatas,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
        }).then(function (response) {
            if (response?.data.hasOwnProperty('cycleError')) {
                showError(response?.data?.cycleError);
            }
           if(response.data.hasOwnProperty('pastDateNtification')){
               showError(response.data?.pastDateNtification);
           }if(response.data.hasOwnProperty('success')){
               showSuccess(response.data?.success)
               setOff_water_reading('')
               setOff_water_image('')
               setOff_reading_at('')
               setRemarks('')
               setOfftake_id('')
           }
           if(response.data.hasOwnProperty('ErrorSubmission')){
             showError(response.data?.ErrorSubmission)
           }
           if (response?.data.hasOwnProperty('imagesError')) {
            showInfo(response?.data?.imagesError);
           }
           if(response.data.hasOwnProperty('recordExistence')){
             showError(response.data?.recordExistence)
           }//
           setSavingLoading(false)
        })
        .catch(function (error) {
            console.log(error.response)
            setSavingLoading(false)
         })
         .then(function () {
         })
    }
  return (
      <div
          style={{
              overflowY: "scroll",
              maxHeight: 530,
              padding: 10,
              fontSize: 13,
              fontFamily: ':"Times New Roman", Times, serif',
          }}
      >
        <Toast ref={toast} />
          <div className="mb-3">
              <label htmlFor="offtake_id" className="form-label">
                  Select Offtake
              </label>
              <select
                  className="form-select col-md-12 col-sm-12 "
                  name="offtake_id"
                  aria-label="Default select example"
                  value={offtake_id}
                  onChange={(e)=>setOfftake_id(e.target.value)}
              >
                  <option value>Select Offtake</option>
                  {offtakeData.map((data)=>(
                        <option key={data.id} value={data.id}>{data.offtake_name}</option>
                      ))}
              </select>
          </div>
          <div className="mb-3">
              <label htmlFor="off_water_reading" className="form-label">
                  Water Reading
              </label>
              <input
                  type="text"
                  name="off_water_reading"
                  value={off_water_reading}
                  onChange={(e)=>setOff_water_reading(e.target.value)}
                  className="p-inputtext col-md-12 col-sm-12 form-control"
              />
          </div>
          <div className="mb-3">
              <label htmlFor="off_water_image" className="form-label">
                  Water reading picture
              </label>
              <input
                  type="file"
                  name="off_water_image"
                  onChange={(e)=>setOff_water_image(e.target.files[0])}
                  className="p-inputtext col-md-12 col-sm-12 form-control"
              />
          </div>
          <div className="mb-3">
              <label htmlFor="off_reading_at" className="form-label">
                  Reading Date
              </label>
              <input
                  type="date"
                  name="off_reading_at"
                  value={off_reading_at}
                  onChange={(e)=>setOff_reading_at(e.target.value)}
                  className="p-datepicker-calendar p-inputtext col-md-12 col-sm-12 form-control"
              />
          </div>
          <div className="mb-3">
              <label htmlFor="remarks" className="form-label">
                  Comment
              </label>
              <textarea
                  className="p-inputtext col-md-12 col-sm-12"
                  name="remarks"
                  value={remarks}
                  onChange={(e)=>setRemarks(e.target.value)}
                  id="exampleFormControlTextarea1"
                  rows={3}
              />
          </div>
          <div className="mb-3">
              <Button label="Save Data" severity="warning" icon="pi pi-save" loading={savingLoading} onClick={()=>saveOfftake()} />
          </div>
      </div>
  );
}

export default OfftakeReadingForm
