import React from 'react'
import BoreholeVerification from './BoreholeVerification'
import OfftakeVerification from './OfftakeVerification'
import TreatmentVerificatio from './TreatmentVerificatio'

const ReadingVerification = () => {
  return (
        <div className="col-md-12">
        <ul
            className="nav nav-tabs justify-content-center"
            id="myTab"
            role="tablist"
        >
            <li className="nav-item" role="presentation">
                <button
                    className="nav-link active"
                    id="borehole_verify-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#borehole_verify-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="borehole_verify-tab-pane"
                    aria-selected="true"
                >
                    Borehole Verification
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="offtake_verify-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#offtake_verify-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="offtake_verify-tab-pane"
                    aria-selected="false"
                >
                    Offtake Verification
                </button>
            </li>
            <li className="nav-item" role="presentation">
                <button
                    className="nav-link"
                    id="treatment_verify-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#treatment_verify-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="treatment_verify-tab-pane"
                    aria-selected="false"
                >
                    Treatment Verification
                </button>
            </li>
        </ul>
        <div
            className="tab-content"
            id="myTabContent"
            style={{ overflowX: "auto", overflowY: "auto", maxHeight: 480 }}
        >
            <div
                className="tab-pane fade show active"
                id="borehole_verify-tab-pane"
                role="tabpanel"
                aria-labelledby="borehole_verify-tab"
                tabIndex={0}
            >
                <BoreholeVerification/>
            </div>
            <div
                className="tab-pane fade"
                id="offtake_verify-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabIndex={0}
            >
                <OfftakeVerification/>
            </div>
            <div
                className="tab-pane fade"
                id="treatment_verify-tab-pane"
                role="tabpanel"
                aria-labelledby="treatment_verify-tab"
                tabIndex={0}
            >
                <TreatmentVerificatio/>
            </div>
        </div>
    </div>
  )
}

export default ReadingVerification
