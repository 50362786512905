import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import BoreholeReadingTable from '../BoreholeReadingComponents/BoreholeReadingTable';
import axios from 'axios';
import { Button } from 'primereact/button';
import OfftakeReadingTable from '../BoreholeReadingComponents/OfftakeReadingTable';
import TreatmentReadingTable from '../BoreholeReadingComponents/TreatmentReadingTable';
import ReadingVerification from '../ReadingVerification/ReadingVerification';
const ReadingDisplayComponents = ({setBoreholeReadingData,boreholeReadingLoading,
    boreholeReadingData,setBoreholeReadingLoading,offtakeReadingData,
    offtakeReadingLoading,setOfftakeReadingData,setOfftakeReadingLoading,
    treatmentReadingData,setTreatmentReadingData,
    treatmentReadingLoading,setTreatmentReadingLoading
}) => {

    const getBoreholeReadings=async()=>{
        //
        setBoreholeReadingLoading(true)
        await axios.get('getallboreholedata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('boreholeReading')){
                setBoreholeReadingData(response?.data?.boreholeReading)
                setBoreholeReadingLoading(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const getOfftakeReadings=async()=>{
        //
        setOfftakeReadingLoading(true)
        await axios.get('getallofftakedata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('offtakeReading')){
                setOfftakeReadingData(response?.data?.offtakeReading)
            }
            setOfftakeReadingLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });
    }
    const getTreatment=async()=>{
        setTreatmentReadingLoading(true)
        await axios.get('getalltreatmentdata')
        .then(function (response) {
            if(response?.data.hasOwnProperty('treatmentData')){
                setTreatmentReadingData(response?.data?.treatmentData)
            }
            setTreatmentReadingLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  return (
      <div className="col-md-7">
          <ul
              className="nav nav-tabs justify-content-center"
              id="myTab"
              role="tablist"
          >
              <li className="nav-item" role="presentation">
                  <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                  >
                      Borehole Readings
                  </button>
              </li>
              <li className="nav-item" role="presentation">
                  <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                  >
                      Offtake Readings
                  </button>
              </li>
              <li className="nav-item" role="presentation">
                  <button
                      className="nav-link"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                  >
                      Treatment Readings
                  </button>
              </li>
              <li className="nav-item" role="presentation">
                  <button
                      className="nav-link"
                      id="daily-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#daily-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="daily-tab-pane"
                      aria-selected="false"
                  >
                      DMA Reading Verification
                  </button>
              </li>
           {/*    <li className="nav-item" role="presentation">
                  <button
                      className="nav-link"
                      id="monthly-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#monthly-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="monthly-tab-pane"
                      aria-selected="false"
                  >
                      DMA Readings Summary
                  </button>
              </li> */}
          </ul>
          <div className="tab-content" id="myTabContent">
              <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                  style={{ overflowY: "auto", maxHeight: 500 }}
              >
                {boreholeReadingLoading ?
                        <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="2" fill="var(--surface-ground)" />
                    :<>
                    <div style={{justifyContent:'flex-end',alignSelf:'flex-end',display:'flex',flexDirection:'row' }}>
                        <Button onClick={()=>getBoreholeReadings()} icon="pi pi-sync" rounded severity="success" raised size="small" />
                    </div>
                        <BoreholeReadingTable  boreholeReadingData={boreholeReadingData}/>
                    </>
                }
              </div>
              <div
                  className="tab-pane fade"
                  id="profile-tab-pane"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  tabIndex={0}
                  style={{ overflowY: "auto", maxHeight: 500 }}
              >
                  {offtakeReadingLoading ?
                  <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="2" fill="var(--surface-ground)" />
                  :
                  <>
                    <div style={{justifyContent:'flex-end',alignSelf:'flex-end',display:'flex',flexDirection:'row' }}>
                         <Button
                         onClick={()=>getOfftakeReadings()}
                          icon="pi pi-sync" rounded severity="warning" raised size="small" />
                    </div>
                         <OfftakeReadingTable offtakeReadingData={offtakeReadingData}/>
                  </>
                    }
              </div>
              <div
                  className="tab-pane fade"
                  id="contact-tab-pane"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                  tabIndex={0}
                  style={{ overflowY: "auto", maxHeight: 500 }}
              >
                 {treatmentReadingLoading ?
                     <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="2" fill="var(--surface-ground)" />
                     :
                     <>
                       <div style={{justifyContent:'flex-end',alignSelf:'flex-end',display:'flex',flexDirection:'row' }}>
                            <Button
                            onClick={()=>getTreatment()}
                             icon="pi pi-sync" rounded severity="danger" raised size="small" />
                       </div>
                            <TreatmentReadingTable treatmentReadingData={treatmentReadingData}/>
                     </>
                }
              </div>
              <div
                  className="tab-pane fade"
                  id="daily-tab-pane"
                  role="tabpanel"
                  aria-labelledby="daily-tab"
                  tabIndex={0}
              >
                  <ReadingVerification/>
              </div>
              <div
                  className="tab-pane fade"
                  id="monthly-tab-pane"
                  role="tabpanel"
                  aria-labelledby="monthly-tab"
                  tabIndex={0}
              >
                  <div id="reportDMA" />
              </div>
          </div>
      </div>
  );
}

export default ReadingDisplayComponents
