import React,{useState,useEffect} from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import axios from 'axios';
import $ from 'jquery';
const ZoneRegistrationComponents = () => {
    const[walkrouteName,setWalkrouteName]=useState('');
    const[zoneName,setZoneName]=useState('');
    const[schemeName,setSchemeName]=useState('');
    const[isLoading,setIsLoading]=useState(false);
    const registerSchemeDetails=async()=>{

            setIsLoading(true);
            await axios.post('api/Storeschemes',
            {
                walkrouteName:walkrouteName.toUpperCase(),
                zoneName:zoneName.toUpperCase(),
                schemeName:schemeName.toUpperCase(),
            }).then(function (response) {
                if(response.data.hasOwnProperty('errors')){
                    console.log(response.data)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)

            })
            .then(function () {
                // always executed
            })
    }
  return (
    <div className='p-panel' style={{ fontSize:13,fontFamily:'times',padding:8 }}>
        <div className='p-card' style={{padding:18 }}>
    <h6 className='text-center'>Register Zones</h6>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Walkroute</label>
        <input type="text" value={walkrouteName} onChange={(e)=>setWalkrouteName(e.target.value)} className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Zone Name</label>
        <input type="text" value={zoneName} onChange={(e)=>setZoneName(e.target.value)} className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">Scheme</label>
        <input type="text" value={schemeName} onChange={(e)=>setSchemeName(e.target.value)} className="p-inputtext col-md-12 col-sm-12 form-control"/>
    </div>
    <div className="mb-3">
        <Button label="Save Data" loading={isLoading} severity="help" onClick={()=>registerSchemeDetails()}/>
    </div>
    </div>
</div>
  )
}

export default ZoneRegistrationComponents
