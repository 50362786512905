/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import './bootstrap';
import 'leaflet/dist/leaflet.css';
import "./libraries/Leaflet.zoominfo/L.Control.Zoominfo.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'primeicons/primeicons.css';
import "primereact/resources/themes/vela-blue/theme.css";
import "primereact/resources/themes/vela-green/theme.css"
import "primereact/resources/primereact.min.css";
import L from 'leaflet';
import "./libraries/Leaflet.zoominfo/L.Control.Zoominfo.js";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster/dist/leaflet.markercluster.js";
import "./libraries/Leaflet.Pancontrol-master/src/L.Control.Pan.css";
import "./libraries/Leaflet.Pancontrol-master/src/L.Control.Pan.js";
import $ from "jquery";
import 'datatables.net';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});


/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import "./components/GeomapComponents/GeoMaps";
import "./components/TechnicalComponents/Technicals";
import "./reportingComponents/DMAReadingReportingComponents";
import "./master-meter-reading/MasterMeterReading";
import "./SummariesGeneration/SummariesGeneration";
