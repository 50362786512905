import axios from 'axios';
import React from 'react'
import ReactDOM from 'react-dom/client';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
const SummariesGeneration = () => {
    const toast = React.useRef(null);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const showError = (msg) => {
        toast.current.show({ severity:'error', summary: 'Error', detail: msg });
    };
    const showSuccess = (msg) => {
        toast.current.show({ severity:'success', summary: 'Success', detail: msg });
    };
    const[readingPeriod,setReadingPeriod]=React.useState([])
    const[readingPeriodLoading,setReadingPeriodLoading]=React.useState(false)
    const[selectedReadingSession,setSelectedReadingSession]=React.useState('');
    const[companySchemes,setCompanySchemes]=React.useState([]);
    const[schemesLoading,setSchemesLoading]=React.useState(true);
    const[selectedScheme,setSelectedScheme]=React.useState('');
    const[schemezones,setSchemeZones]=React.useState([]);
    const[schemeZonesLoading,setSchemeZonesLoading]=React.useState(false)
    const[selectedZone,setSelectedZones]=React.useState('')
    const[zoneWalkroute,setZoneWalkroute]=React.useState([]);
    const[zoneWalkrouteLoading,setZoneWalkrouteLoading]=React.useState(false)
    const[selectedWalkroute,setSelectedWalkroute]=React.useState('')
    const[boreholeData,setBoreholeData]=React.useState([]);
    const[boreholeDataLoading,setBoreholeDataLoading]=React.useState(false)
    const[offtakeData,setOfftakeData]=React.useState([]);
    const[offtakeDataLoading,setOfftakeDataLoadding]=React.useState(false)
    const[treamentData,setTreatmentData]=React.useState([]);
    const[treatmentDataLoading,setTreatmentDatsLoading]=React.useState(false)
    const[selectedBorehole,setSelectedBorehole]=React.useState('')
    const[selectedOfftake,setSelectedOfftake]=React.useState('')
    const[selectedTreatment,setSelectedTreatment]=React.useState('')
    const[loading,setLoading]=React.useState(false)

    const  getReadingPeriod=async()=>{
        setReadingPeriodLoading(true)
        await axios.get('api/getreadingperiod').
        then((response)=>{
            if(response?.data.hasOwnProperty('cycles')){
                setReadingPeriod(response.data?.cycles)
                setReadingPeriodLoading(false)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    React.useEffect(()=>{
        getReadingPeriod();
    },[])
    const  getCompanySchemes=async()=>{
        setSchemesLoading(true)
        await axios.get('api/getallSchemes').
        then((response)=>{
            if(response?.data.hasOwnProperty('companyschemes')){
                setCompanySchemes(response.data?.companyschemes)
                setSchemesLoading(false)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    React.useEffect(()=>{
        getCompanySchemes();
    },[])
    const getCompanySchemeZones=async()=>{
        setSchemeZonesLoading(true);
        await axios.get('api/getSubZonalSchemes/'+selectedScheme).
        then((response)=>{
            if(response?.data.hasOwnProperty('subzonals')){
                setSchemeZones(response.data?.subzonals)
                setSchemeZonesLoading(false)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    React.useEffect(()=>{
        if(selectedScheme !==''){
            getCompanySchemeZones();
            setZoneWalkroute([])
            setSelectedZones('')
            setSelectedWalkroute('')
            setBoreholeData([])
            setSelectedBorehole('')
            setOfftakeData([])
            setSelectedOfftake('')
            setTreatmentData([])
            setSelectedTreatment('')
        }
        if(selectedScheme ===''){
            setSchemeZones([])
            setZoneWalkroute([])
            setSelectedZones('')
            setSelectedWalkroute('')
            setBoreholeData([])
            setSelectedBorehole('')
            setOfftakeData([])
            setSelectedOfftake('')
            setTreatmentData([])
            setSelectedTreatment('')
        }
    },[selectedScheme])
    const getWalkrouteData=async()=>{
        setZoneWalkrouteLoading(true)
        await axios.get('api/getWalkroutes/'+selectedZone).
        then((response)=>{
            if(response?.data.hasOwnProperty('zonesWalkroute')){
                setZoneWalkroute(response.data?.zonesWalkroute)
                setZoneWalkrouteLoading(false)
            }
        }).catch((error)=>{
            console.log(error);
        })
    }
    React.useEffect(()=>{
        if(selectedZone !==''){
            getWalkrouteData();
            setSelectedWalkroute('')
            setBoreholeData([])
            setSelectedBorehole('')
            setOfftakeData([])
            setSelectedOfftake('')
            setTreatmentData([])
            setSelectedTreatment('')
        }if(selectedZone===''){
            setZoneWalkroute([])
            setSelectedWalkroute('')
            setBoreholeData([])
            setSelectedBorehole('')
            setOfftakeData([])
            setSelectedOfftake('')
            setTreatmentData([])
            setSelectedTreatment('')
        }
    },[selectedZone])

    const getWaterSources=async()=>{
        setBoreholeDataLoading(true)
        setOfftakeDataLoadding(true)
        setTreatmentDatsLoading(true)
        await axios.post('api/getsources',{
            selectedScheme:selectedScheme,
            selectedZone:selectedZone,
            selectedWalkroute:selectedWalkroute
        }).then((response)=>{
            if(response?.data.hasOwnProperty('borehole')){
                setBoreholeData(response?.data?.borehole)
            }
            if(response?.data.hasOwnProperty('offtake')){
                setOfftakeData(response?.data?.offtake)
            }
            if(response?.data.hasOwnProperty('treatment')){
                setTreatmentData(response?.data?.treatment)
            }
            setBoreholeDataLoading(false)
            setOfftakeDataLoadding(false)
            setTreatmentDatsLoading(false)
        }).catch((error)=>{
            console.log(error);
            setBoreholeDataLoading(false)
           setOfftakeDataLoadding(false)
            setTreatmentDatsLoading(false)
        })
    }

    React.useEffect(()=>{
        if(selectedScheme !=='' || selectedZone !=='' || selectedWalkroute !==''){
            getWaterSources()
        }
    },[selectedScheme,selectedZone,selectedWalkroute])

    const searchDataSummaries=async()=>{
        if(selectedReadingSession ===''){
            showError('Select reading session report')
            return false
        }
        const search_parameters={
            selectedReadingSession:selectedReadingSession,
            selectedScheme:selectedScheme,
            selectedZone:selectedZone,
            selectedWalkroute:selectedWalkroute,
            selectedBorehole:selectedBorehole,
            selectedOfftake:selectedOfftake,
            selectedTreatment:selectedTreatment
        }
        window.open(route('summaryResults',search_parameters),'_blank');
    }

  return (
    <Card >
    <div className="row">
    <div className="col-md-3">
    <Toast ref={toast} />
    {!readingPeriodLoading && readingPeriod.length > 0 ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select Reading session</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedReadingSession}
                    onChange={(e)=>setSelectedReadingSession(e.target.value)}
                    >
                        <option value="">Select Reading session</option>
                        {readingPeriod.map((data)=>(
                            <option key={data.id} value={data.cycle_number}>Session { data.month_name}-{ data.cycle_year}</option>
                        ))}
                    </select>
                </div>
            </div>
            : <div className="text-center">
            <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>

        }
    </div>
    <div className='col-md-3'>
        {schemesLoading ?
            <div className="text-center">
                <div className="spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            :
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Schemes</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedScheme}
                    onChange={(e)=>setSelectedScheme(e.target.value)}
                    >
                        <option value="">Select Scheme</option>
                        {companySchemes.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.schemename}</option>
                        ))}
                    </select>
                </div>
            </div>
        }
        {(!schemeZonesLoading && schemezones.length > 0) ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select zone</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedZone}
                    onChange={(e)=>setSelectedZones(e.target.value)}
                    >
                        <option value="">Select zone</option>
                        {schemezones.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.zone_name}</option>
                        ))}
                    </select>
                </div>
            </div>
            :
            <>
            {schemeZonesLoading &&
            <div className="text-center">
                <div  className="spinner-border-sm spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            </>

        }

        {(!zoneWalkrouteLoading && zoneWalkroute.length > 0) ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select Walkroute</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedWalkroute}
                    onChange={(e)=>setSelectedWalkroute(e.target.value)}
                    >
                        <option value="">Select Walkroute</option>
                        {zoneWalkroute.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.walkroute_name}</option>
                        ))}
                    </select>
                </div>
            </div>
            :
            <>
            {zoneWalkrouteLoading &&
            <div className="text-center">
                <div  className="spinner-border-sm spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            </>

        }
    </div>
    <div className="col-md-3">
    {(!boreholeDataLoading && boreholeData.length > 0) ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select Borehole</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedBorehole}
                    onChange={(e)=>setSelectedBorehole(e.target.value)}
                    >
                        <option value="">Select Borehole</option>
                        {boreholeData.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.borehole_name}</option>
                        ))}
                    </select>
                </div>
            </div>
            :
            <>
            {boreholeDataLoading &&
            <div className="text-center">
                <div  className="spinner-border-sm spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            </>

        }
        {(!offtakeDataLoading && offtakeData.length > 0) ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select Offtake</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedOfftake}
                    onChange={(e)=>setSelectedOfftake(e.target.value)}
                    >
                        <option value="">Select Offtake</option>
                        {offtakeData.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.offtake_name }</option>
                        ))}
                    </select>
                </div>
            </div>
            :
            <>
            {offtakeDataLoading &&
            <div className="text-center">
                <div  className="spinner-border-sm spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            </>

        }
        {(!treatmentDataLoading && treamentData.length > 0) ?
            <div>
                <div className="mb-1">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select Treatment</label>
                    <select name="offtake_walkroute" className="form-select p-inputtext col-md-12 col-sm-12" aria-label="Default select example"
                    value={selectedTreatment}
                    onChange={(e)=>setSelectedTreatment(e.target.value)}
                    >
                        <option value="">Select Treatment</option>
                        {treamentData.map((data)=>(
                            <option key={data.id} value={data.id}>{ data.treatment_name }</option>
                        ))}
                    </select>
                </div>
            </div>
            :
            <>
            {treatmentDataLoading &&
            <div className="text-center">
                <div  className="spinner-border-sm spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
            </>

        }
    </div>
     <div className="col-md-2">
     <div className="mb-3">
        <Button loading={loading} label="Search" onClick={()=>searchDataSummaries()} raised  severity="info" icon="pi pi-search" />
    </div>
     </div>
    </div>
    </Card>
  )
}

export default SummariesGeneration
if (document.getElementById('SummariesGeneration')) {
    const Index = ReactDOM.createRoot(document.getElementById("SummariesGeneration"));
    Index.render(
        <React.StrictMode>
            <SummariesGeneration/>
        </React.StrictMode>
    )
}
