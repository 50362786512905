import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import TreatmentVerificationModal from './TreatmentVerificationModal';
const TreatmentVerificatio = () => {
    const toast = React.useRef(null);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const[treatmentVerificationData,setTreatmentVerificationData]=React.useState([])
    const[verificationDataLoading,setVerificationDataLoading]=React.useState(true)
    const [verifyReading,setVerifyReading]=React.useState('')
    const[verificationData,setVerificationData]=React.useState({})
    const[loading,setLoading]=React.useState(false)
    const[showDataModal,setShowDataModal]=React.useState(false)
    const[reloadData,setReloadData]=React.useState(false)
    const getVerificationData=async()=>{
        setVerificationDataLoading(true)
        await axios.get('getTreatmentVerificationData')
        .then(function (response) {
            if(response?.data.hasOwnProperty('treatmentData')){
                setTreatmentVerificationData(response?.data?.treatmentData)
                setVerificationDataLoading(false)

            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
        React.useEffect(()=>{
            getVerificationData();
        },[])


        React.useEffect(()=>{
            if(reloadData===true){
                getVerificationData();
            }
        },[reloadData])

        const getTreatmentToVerify=async(id)=>{
            setLoading(true)
            await axios.get('GetVerificationTreatmentReading/'+id)
            .then(function (response) {
                if(response.data.hasOwnProperty('TreatmentReading')){
                    setVerificationData(response?.data?.TreatmentReading)
                };
                setLoading(false)
                setShowDataModal(true);
              })
              .catch(function (error) {
                showInfo('No access rights to execute the operation');
                setLoading(false)
              });
              setReloadData(false);
        }
        React.useEffect(()=>{
            if(verifyReading !==''){
                getTreatmentToVerify(verifyReading);
            }
        },[verifyReading])

  return (
    <div className="col-md-12 col-sm-12 col-lg-12">
         {verificationDataLoading ?
          <ProgressSpinner style={{ width: "50px", height: "50px" }} strokeWidth="2" fill="var(--surface-ground)"/>:
        <div className="bg-white table-responsive">
            <table className="p-datatable table table-striped table-bordered">
                <thead>
                    <tr>
                        <th className="text-nowrap"></th>
                        <th className="text-nowrap">Treatment</th>
                        <th className="text-nowrap">Reading Date</th>
                        <th className="text-nowrap">Water P.R</th>
                        <th className="text-nowrap">Water C.R</th>
                        <th className="text-nowrap">Cons (M<sup>3</sup>)</th>
                        <th className="text-nowrap">Power C.R</th>
                        <th className="text-nowrap">Power P.R</th>
                        <th className="text-nowrap">Usage(kWh)</th>
                        <th className="text-nowrap">Alum (kgs)</th>
                        <th className="text-nowrap">Soda (kgs)</th>
                        <th className="text-nowrap">HTH (kgs)</th>
                        <th className="text-nowrap">Remarks</th>
                        <th className="text-nowrap">Read By</th>
                    </tr>
                </thead>
                <tbody>
                {treatmentVerificationData.map((data) => (
                        <tr key={data.treatId}>
                                                    <td>
                            {loading && verifyReading == data.treatId ? (
                                <div
                                    className="spinner-border text-danger spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                <>
                                    {(verifyReading == data.treatId) ? (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='verification'
                                            value={data.treatId}
                                            onChange={(e) =>{
                                            setVerifyReading(
                                                e.target.value
                                            )
                                            }
                                            }
                                            defaultChecked
                                        />
                                    ) : (
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name='verification'
                                            value={data.treatId}
                                            onChange={(e) =>{
                                            setVerifyReading(
                                                e.target.value
                                            )
                                            }
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </td>
                            <td className="text-center text-nowrap">{ data.treatment_name }</td>
                            <td className="text-center">{ data.treat_reading_at}</td>
                            <td className="text-center">{ data.treat_water_reading}</td>
                            <td className="text-center">{ data.previous_water_reading }</td>
                            <td className="text-center">{ data.water_consumed}</td>
                            <td className="text-center">{ data.treat_power_reading }</td>
                            <td className="text-center">{ data.previous_power_reading }</td>
                            <td className="text-center">{ data.power_consumed }</td>
                            <td className="text-center">{ data.alum }</td>
                            <td className="text-center">{ data.soda }</td>
                            <td className="text-center">{ data.hth }</td>
                            <td className="text-center">{ data.treat_remarks }</td>
                            <td className="text-center">{ data.read_by }</td>
                        </tr>
                ))}

                </tbody>
            </table>
        </div>
         }
         <TreatmentVerificationModal
            showDataModal={showDataModal}
            setShowDataModal={setShowDataModal}
            verificationData={verificationData}
            setReloadData={setReloadData}
         />
    </div>
  )
}

export default TreatmentVerificatio
