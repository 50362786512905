import React from 'react'
import BoreHoleReadingForm from './BoreHoleReadingForm';
import OfftakeReadingForm from './OfftakeReadingForm';
import TreatmentReadingForm from './TreatmentReadingForm';

const IndexReadingComponents = ({boreholeData,offtakeData,treatmentData}) => {
  return (
          <>
              <div className="col-md-2">
                  <ul className="nav flex-column" style={{ cursor: "pointer" }}>
                      <li className="nav-item" role="presentation">
                          <a
                              className="nav-link active"
                              id="borehole-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#borehole-tab-pane"
                              role="tab"
                              aria-controls="borehole-tab-pane"
                              aria-selected="true"
                          >
                              BoreHole Reading
                          </a>
                      </li>
                      <li className="nav-item" role="presentation">
                          <a
                              className="nav-link"
                              id="offtake-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#offtake-tab-pane,#offtake-tab-pane"
                              role="tab"
                              aria-controls="offtake-tab-pane"
                              aria-selected="false"
                          >
                              Offtake Reading
                          </a>
                      </li>
                      <li className="nav-item" role="presentation">
                          <a
                              className="nav-link"
                              id="treatment-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#treatment-tab-pane"
                              role="tab"
                              aria-controls="treatment-tab-pane"
                              aria-selected="false"
                          >
                              Treatment Reading
                          </a>
                      </li>
                  </ul>
              </div>
              <div className="col-md-3">
                  <div className="tab-content">
                      <div
                          className="tab-pane fade show active"
                          id="borehole-tab-pane"
                          role="tabpanel"
                          aria-labelledby="borehole-tab"
                          tabIndex={0}
                      >
                          <BoreHoleReadingForm boreholeData={boreholeData}/>
                      </div>
                      <div
                          className="tab-pane fade"
                          id="offtake-tab-pane"
                          role="tabpanel"
                          aria-labelledby="offtake-tab"
                      >
                         <OfftakeReadingForm offtakeData={offtakeData}/>
                      </div>
                      <div
                          className="tab-pane fade"
                          id="treatment-tab-pane"
                          role="tabpanel"
                          aria-labelledby="treatment-tab"
                          tabIndex={0}
                      >
                          <TreatmentReadingForm treatmentData={treatmentData}/>
                      </div>
                  </div>
              </div>
          </>
  );
}

export default IndexReadingComponents
