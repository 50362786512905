import React from 'react'
import ReactDOM from 'react-dom/client';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import axios from 'axios';
import { Calendar } from 'primereact/calendar';
const DMAReadingReportingComponents = () => {
    const[isLoadingSchemes,setIsLoadingSchemes]=React.useState(false);
    const[isLoadingSchemeZones,setIsLoadingSchemeZones]=React.useState(false);
    const[isLoadingZonesWalkroute,setIsLoadingZonesWalkroute]=React.useState(false);
    const[isLoadingZonesBorehole,setIsLoadingZonesBorehole]=React.useState(false);
    const [isLoadingOfftakes,setIsLoadingOfftakes]=React.useState(false);
    const [isLoadingTreatment,setIsLoadingTreatment]=React.useState(false);
    const[schemesData,setSchemesData]=React.useState([]);
    const[schemeZonesData,setSchemeZonesData]=React.useState([]);
    const[zonalWalkrouteData,setZonalWalkrouteData]=React.useState([]);
    const[zonalWalkrouteBorehole,setZonalWalkrouteBorehole]=React.useState([]);
    const[offtakeData,setOfftakeData]=React.useState([])
    const[treatmentData,setTreatmentData]=React.useState([])

const [selectedScheme,setSelectedScheme]=React.useState('');
const [selectedZone,setSelectedZone]=React.useState('');
const [selectedWalkroute,setSelectedWalkroute]=React.useState('');
const [selectedTreatment, setSelectedTreatment] = React.useState('');
const [selectedBorehole,setSelectedBorehole]= React.useState(0);
const [selectedOfftake,setSelectedOfftake]= React.useState('');
const [startReportDate, setStartReportDateDate] = React.useState('');
const [endReportDate,setEndReportDate] = React.useState('');
const getSchemes=async()=>{
    setIsLoadingSchemes(true)
    await axios.get('companySchemes')
    .then(function (response) {
        if(response.hasOwnProperty('data')){
            setIsLoadingSchemes(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.schemename}
              })
            setSchemesData(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingSchemes(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}
const getSchemeZones=async()=>{
    setIsLoadingSchemeZones(true)
    await axios.get('schemeZones/'+selectedScheme.code)
    .then(function (response) {
        if(response.hasOwnProperty('data')){
            setIsLoadingSchemeZones(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.zone_name}
              })
              setSchemeZonesData(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingSchemeZones(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}
const getZonesWalkroute=async()=>{
    setIsLoadingZonesWalkroute(true)
    await axios.get('zonalWalkroute/'+selectedZone.code)
    .then(function (response) {

        if(response.hasOwnProperty('data')){
            setIsLoadingZonesWalkroute(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.walkroute_name}
              })
              setZonalWalkrouteData(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingZonesWalkroute(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}
const getZonesBoreholes=async()=>{
    setIsLoadingZonesBorehole(true)
    await axios.get('boreholeZonal/'+selectedWalkroute.code)
    .then(function (response) {
        if(response.hasOwnProperty('data')){
            setIsLoadingZonesBorehole(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.borehole_name}
              })
              setZonalWalkrouteBorehole(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingZonesBorehole(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}
const getZonesOfftakes=async()=>{
    setIsLoadingOfftakes(true)
    await axios.get('offtakeZonal/'+selectedWalkroute.code)
    .then(function (response) {
        if(response.hasOwnProperty('data')){
            setIsLoadingOfftakes(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.offtake_name}
              })
              setOfftakeData(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingOfftakes(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}
const getZonesTreatment=async()=>{

    setIsLoadingTreatment(true)
    await axios.get('treatmnetZonal/'+selectedWalkroute.code)
    .then(function (response) {
        if(response.hasOwnProperty('data')){
            setIsLoadingTreatment(false)
            let newArray = response.data.map((item) => {
                return {code: item.id, name: item.treatment_name}
              })
              setTreatmentData(newArray)
        }
    })
    .catch(function (error) {
        setIsLoadingTreatment(false)
        console.log(error.message)
    })
    .then(function () {
        // always executed
    })
}

const getProductionReport=()=>{
    if(selectedScheme===''){
        alert('Select scheme');
        return false
    }
  const queryParameters={
    startReportDate:startReportDate,
    endReportDate:endReportDate,
    companyScheme:selectedScheme?.code,
    zonalScheme:selectedZone?.code,
    zonalWalkroute:selectedWalkroute?.code,
    selectedBorehole:selectedBorehole?.code,
    selectedOfftake:selectedOfftake?.code,
    selectedTreatment:selectedTreatment?.code
  }
    const url = location.origin +'/DMA/'+JSON.stringify(queryParameters)+'/edit';
    window.open(url,'_blank');
}

//getProductionReport
React.useEffect(()=>{
    getSchemes();
},[])
React.useEffect(()=>{
    if(selectedScheme !==undefined && selectedScheme !==''){
     getSchemeZones();
    }
},[selectedScheme])
React.useEffect(()=>{
    if(selectedZone !==undefined && selectedZone !==''){
        setZonalWalkrouteData([])
        getZonesWalkroute();
    }
    if(selectedZone ===undefined){
       //setSchemeZonesData([])
       setZonalWalkrouteData([])
       setZonalWalkrouteBorehole([])
       setOfftakeData([])
       setTreatmentData([])
       setSelectedWalkroute('')
    }

},[selectedZone])
React.useEffect(()=>{
    if(selectedWalkroute !==undefined){
        getZonesBoreholes();
        getZonesOfftakes();
        getZonesTreatment();
    }
    if(selectedWalkroute ===undefined ){
       setZonalWalkrouteBorehole([])
       setOfftakeData([])
       setTreatmentData([])
    }
},[selectedWalkroute])

  return (
    <Card style={{ margin:10 }} className="col-md-12" >
        <div className="col-md-6 col-lg-6 col-sm-12 offset-md-3">
            <div style={{ display:'flex',flexDirection:'row',justifyContent:'space-evenly' }}>
                <div className="mb-2">
                    <label htmlFor="remarks" className="form-label">Start Date</label>
                    <Calendar value={startReportDate} onChange={(e) => setStartReportDateDate(e.value)} />
                </div>
                <div className="mb-2">
                    <label htmlFor="remarks" className="form-label">End Date</label>
                    <Calendar value={endReportDate} onChange={(e) => setEndReportDate(e.value)} />
                </div>
            </div>
            {(!isLoadingSchemes && schemesData !==undefined && schemesData.length > 0) &&
             <div className="mb-2">
            <label htmlFor="remarks" className="form-label">Scheme</label>
            <Dropdown   value={selectedScheme} onChange={(e) => setSelectedScheme(e.value)} options={schemesData} optionLabel="name"
                placeholder="Select a scheme" className="col-md-12 col-sm-12 w-full " />

            </div>
            }
            {(!isLoadingSchemeZones && schemeZonesData !==undefined && schemeZonesData.length>0) &&
            <div className="mb-2">
                <label htmlFor="remarks" className="form-label">Zone</label>
                <Dropdown showClear value={selectedZone} onChange={(e) => setSelectedZone(e.value)} options={schemeZonesData} optionLabel="name"
                    placeholder="Select a Zone" className="col-md-12 col-sm-12 w-full " />
            </div>
             }
           {(!isLoadingZonesWalkroute && zonalWalkrouteData !==undefined && zonalWalkrouteData.length >0) &&
                <div className="mb-3">
                    <label htmlFor="remarks" className="form-label">Walkroute</label>

                    <Dropdown showClear value={selectedWalkroute} onChange={(e) => setSelectedWalkroute(e.value)} options={zonalWalkrouteData} optionLabel="name"
                        placeholder="Select Walkroute" className="col-md-12 col-sm-12 w-full " />

                </div>
            }
        </div>

        <div style={{ display:'flex',flexDirection:'row',justifyContent:'space-evenly' }}>
        {(!isLoadingZonesBorehole &&  zonalWalkrouteBorehole !==undefined && zonalWalkrouteBorehole.length>0 ) &&
        <div className="mb-3">
            <label htmlFor="remarks" className="form-label">BoreHoles</label>
            <Dropdown showClear value={selectedBorehole} onChange={(e) => setSelectedBorehole(e.value)} options={zonalWalkrouteBorehole} optionLabel="name"
                placeholder="Select Borehole" className="col-md-12 col-sm-12 w-full " />
        </div>
        }

        {(!isLoadingOfftakes && offtakeData !==undefined && offtakeData.length>0 ) &&
        <div className="mb-3">
            <label htmlFor="remarks" className="form-label">Offtakes</label>
            <Dropdown showClear  value={selectedOfftake} onChange={(e) => setSelectedOfftake(e.value)} options={offtakeData} optionLabel="name"
                placeholder="Select Offtake" className="col-md-12 col-sm-12 w-full " />
        </div>
        }
        {(!isLoadingTreatment && treatmentData !==undefined && treatmentData.length>0) &&
        <div className="mb-3">
            <label htmlFor="remarks" className="form-label">Treatment</label>
            <Dropdown  showClear value={selectedTreatment} onChange={(e) => setSelectedTreatment(e.value)} options={treatmentData} optionLabel="name"
                placeholder="Select Treatment" className="col-md-12 col-sm-12 w-full md:w-14rem" />
        </div>
        }
         </div>
        <div className="mb-3 offset-md-5">
            <Button onClick={()=>getProductionReport()} label="Generate Report" severity="info" />
        </div>
    </Card>
  )
}

export default DMAReadingReportingComponents
if (document.getElementById('reportDMA')) {
    const Index = ReactDOM.createRoot(document.getElementById("reportDMA"));
    Index.render(
        <React.StrictMode>
            <DMAReadingReportingComponents/>
        </React.StrictMode>
    )
}
