import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Image } from 'primereact/image';
const Navigation = ({
    isKiambuLoading,showLayers, setShowLayers,
    kiambuCountyLayer, setKiambuCountyLayer,
    /* githunguri sub county */
    isGithsubLoading,
    githSubShow,
    setGithuSubShow,
    /* end githunguri sub */
    isLariLowerLoading,
    lariLowerShow,
    setLariLowerShow,
    /* billing data */
    billingRoutesShow,
    setBillingRoutesShow,
    billingRoutesLoading,
    /* end billing data */
    customerAccountShow,
    setCustomerAccountShow,
    customerAccountLoading,
    searchText,
    setSearchText,
    disableSearch,
    filteredText,
    setSelectedaText,
    /* selections */
    setSelectionData,
    setSelectedBasemap,
    setHomeControl,
    /* supply line */
    supplyShow,
    setSupplyShow,
    supplyLoading,
    /* valves */
    valvesShow,
    setValvesShow,
    valvesLoading,
    /* customer mters */
    meterShow,
    setmeterShow,
    meterLoading
}) => {
    const loginUrl=  route('login');
    const [highlight, setHighlight] = React.useState();
    const [selectedBasemapId,setSelectedBasemapId]= React.useState(6);
    const baseLayers=[{id:1,name:'Google Traffic',url:'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}'},
    {id:2,name:'Google Terrain',url:'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'}
    ,{id:3,name:'Google Satellite',url:'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'},
    {id:4,name:'Google Hybrid',url:'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'},
    {id:5,name:'Google Street',url:'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'},
    {id:6,name:'OpenStreet Map',url:'https://tile.openstreetmap.org/{z}/{x}/{y}.png'}
  ]
  const [legend,setLegend]= React.useState(false);
  const [searchQuery,setSearchQuery]= React.useState(false);
  return (
    <>
    <nav className="navbar navbar-expand-lg bg-body-tertiary p-menubar"
        style={{ fontFamily:'times',fontSize:16,cursor:'pointer'}}>
    <div className="container-fluid">
        <a className="navbar-brand" href="#">
        <img src="images/image_githu.png" alt="Logo" width={35} height={35} className="d-inline-block align-text-top" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav">
            <li className="nav-item mt-2 mr-2" onClick={() => setShowLayers(true)}>
                    <i className="pi pi-server me-2" style={{ color: 'slateblue' }}></i>
                    Layers
            </li>
            <li className="nav-item mt-2 me-2" onClick={()=>setLegend(true)}>
                    <i className="pi pi-book me-2" style={{ color: '#43A047' }}></i>
                    Legend
            </li>
            <li className="nav-item mt-2 me-2" onClick={()=>setSelectionData({})}>
                    <i className="pi pi-ban me-2 mt-1" style={{ color: '#FF5722' }}></i>
                    Clear Selection
            </li>
            <li className="nav-item dropdown mt-2 me-2">
                 <i className="pi pi-map me-2 mt-1dropdown-toggle" style={{ color: '#FF5722' }} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    &nbsp;Basemaps
                    </i>
                <ul className="dropdown-menu">
                    {baseLayers.map((data,index)=>{
                        return <li key={data.id} onClick={()=>{
                                   setSelectedBasemapId(data.id)
                                   setSelectedBasemap(data)
                                   }
                                   }>
                                   <a className={selectedBasemapId ===data.id ? "dropdown-item active"  : "dropdown-item" }href="#">{data.name}
                                   </a>
                                </li>
                    })}
                </ul>
            </li>

            <li className="nav-item mt-2 me-2">
                    <i className="pi pi-info-circle me-2 mt-1" style={{ color: '#7986CB' }}></i>
                    Information
            </li>
            <li className="nav-item mt-2 me-2" onClick={()=>setHomeControl(true)}>

                    <i className="pi pi-home me-2 mt-1" style={{ color: '#7986CB' }}></i>
                    Home
            </li>
            <li className="nav-item mt-2 me-2" onClick={()=>setSearchQuery(true)}>
                <i className="pi pi-search me-2 mt-1" style={{ color: '#7986CB' }}></i>
                Query
            </li>
            <li className="nav-item">
                <a className="nav-link text-white" href={loginUrl}>
                    <i className="pi pi-unlock me-2" style={{fontSize: '1rem', color: '#29B6F6' }}></i>
                    Login
                </a>
            </li>
        </ul>
        </div>
        <form className="d-flex" role="search">
        <input className="form-control me-2" type="search"
         placeholder="Search" aria-label="Search" value={searchText}
          onChange={(e)=>setSearchText(e.target.value)} style={{width:270}}/>
        </form>
        {searchText !=="" &&
        	<ul
                className='list-group p-menu-list text-dark'
                style={{
                    border: '1px solid #999',
                    maxHeight: 300,
                    overflowY: 'auto',
                    zIndex: 2000,
                    position: 'absolute',
                    backgroundColor: 'white',
                    width: 270,
                    fontFamily: 'font-family: Georgia, Times New Roman, Times, serif',
                    fontSize: 12,
                    marginRight: -13,
                    marginTop: -13,
                    right:30,
                    top:80,
                    padding:2,
                    cursor:'pointer'
                }}
            >{filteredText.length > 0 ? filteredText.map((keys,index)=>{
                        return(
                            <li
                                key={keys.id}
                                onClick={
                                    ()=>{
                                        setSearchText(keys.account_nu)
                                        setHighlight(index);
                                        setSelectedaText(keys)
                                    }
                                }
                                className={
                                    highlight === index
                                        ? 'mt-1 list-group-item active'
                                        : 'mt-1 list-group-item'
                                }
                            >
                              A/C  {keys.account_nu} - Meter No. {keys.meter_serial}
                            </li>
                        )
                    }) : <li>No match found</li>}
            </ul>
        }
    </div>
</nav>
    <Dialog  position='bottom-right' visible={showLayers} modal={false} style={{ Width: '25vw',fontFamily:'times',fontSize:13}}
    onHide={() => setShowLayers(false)} breakpoints={{ '960px': '75vw', '641px': '55vw' }}>
        <div style={{ fontSize:14 }}>
        <h6 className='justify-content-center'>Technical Data</h6>
           <div  className="flex flex-wrap  gap-1">
                <Checkbox value={supplyShow} onChange={()=>setSupplyShow((prev)=>!prev)} checked={supplyShow}
                />
                <label htmlFor="customeraccounts" className="ml-2">Supply Line</label>
                {
                supplyLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
            <div  className="flex flex-wrap  gap-1 mt-1">
                <Checkbox value={valvesShow} onChange={()=>setValvesShow((prev)=>!prev)} checked={valvesShow}
                />
                <label htmlFor="customeraccounts" className="ml-2">Valves</label>
                {
                valvesLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
            <div  className="flex flex-wrap  gap-1 mt-1">
                <Checkbox
                />
                <label htmlFor="customeraccounts" className="ml-2">Incidences</label>

            </div>
            <div  className="flex flex-wrap  gap-1 mt-1">
                <Checkbox
                />
                <label htmlFor="customeraccounts" className="ml-2">DMA Meters</label>

            </div>
        <h6 className='justify-content-center'>Billing Data</h6>
            <div  className="flex flex-wrap  gap-1">
            <Checkbox value={meterShow} onChange={()=>setmeterShow((prev)=>!prev)} checked={meterShow}
                />
                <label htmlFor="customeraccounts" className="ml-2">Customer meters</label>
                {
                meterLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }

            </div>
            <div  className="flex flex-wrap  gap-1 mt-1">
                <Checkbox value={customerAccountShow} onChange={()=>setCustomerAccountShow((prev)=>!prev)} checked={customerAccountShow}
                />
                <label htmlFor="customeraccounts" className="ml-2">Customer Accounts</label>
                {
                customerAccountLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
            <div  className="flex flex-wrap  gap-1  mt-1">
                <Checkbox value={billingRoutesShow} onChange={()=>setBillingRoutesShow((prev)=>!prev)} checked={billingRoutesShow}
                />
                <label htmlFor="billingroutes" className="ml-2">Billing routes</label>
                {
                billingRoutesLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
        <h6 className='justify-content-center'>Coverage Area Wards</h6>
            <div  className="flex flex-wrap  gap-1">
                <Checkbox value={githSubShow} onChange={()=>setGithuSubShow((prev)=>!prev)} checked={githSubShow}
                />
                <label htmlFor="githsubcounty" className="ml-2">Githunguri Sub County</label>
                {
                isGithsubLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
            <div  className="flex flex-wrap  gap-1 mt-1">
                <Checkbox value={lariLowerShow} onChange={()=>setLariLowerShow((prev)=>!prev)} checked={lariLowerShow}
                />
                <label htmlFor="lowerlari" className="ml-2">Lower Lari</label>
                {
                isLariLowerLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>
        <h6 className='justify-content-center'>Other data</h6>
            <div  className="flex flex-wrap  gap-1">
                <Checkbox value={kiambuCountyLayer} onChange={()=>setKiambuCountyLayer((prev)=>!prev)} checked={kiambuCountyLayer}
                />
                <label htmlFor="kiambucounty" className="ml-2">Kiambu County</label>
                {
                isKiambuLoading && <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="8" fill="var(--surface-ground)"/>
                }
            </div>

        </div>
    </Dialog>

    {/* legend */}
    <Dialog style={{ Width: '25vw',fontFamily:'times',fontSize:14}} modal={false} visible={legend}  position="bottom-left"
    onHide={() => setLegend(false)} breakpoints={{ '960px': '75vw', '641px': '55vw' }}>
        <table className='table text-white table-bordered'>
            <tbody >
                <tr>
                    <td><Image src="images/mapicons/meters.png" width={15} height={15}/></td>
                    <td>Active Connections</td>
                </tr>
                <tr>
                    <td><Image src="images/mapicons/disconnected.png" width={15} height={15}/></td>
                    <td>Disconnected Connections</td>
                </tr>
                <tr>
                    <td><Image src="images/mapicons/inactive-meters.png" width={15} height={15}/></td>
                    <td>Inactive Connections</td>
                </tr>
                <tr>
                    <td><Image src="images/mapicons/gate_valves.png" width={15} height={15}/></td>
                    <td>Valves</td>
                </tr>
                <tr>
                    <td><Image src="images/mapicons/customer_meter.png" width={20} height={18}/></td>
                    <td>Customer Meter</td>
                </tr>
            </tbody>
        </table>
   </Dialog>

   {/* query search */}
   <Dialog header="Query Filter Panel" modal={false} visible={searchQuery} onHide={() => setSearchQuery(false)}
    style={{ width: '20vw',fontFamily:'times',fontSize:14 }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
    <p className="m-0">

    </p>
   </Dialog>
</>
  )
}

export default Navigation
