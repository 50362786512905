import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import VerificationModal from './VerificationModal';
const BoreholeVerification = () => {
    const toast = React.useRef(null);
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
   const[boreholeVerificationData,setBoreholeVerificationData]=React.useState([])
   const[verificationDataLoading,setVerificationDataLoading]=React.useState(true)
   const [verifyReading,setVerifyReading]=React.useState('')
   const[verificationData,setVerificationData]=React.useState({})
   const[loading,setLoading]=React.useState(false)
   const[showDataModal,setShowDataModal]=React.useState(false)
   const[reloadData,setReloadData]=React.useState(false)
   const getVerificationData=async()=>{
    setVerificationDataLoading(true)
    await axios.get('getBoreholeReadings')
    .then(function (response) {
        if(response?.data.hasOwnProperty('boreholeReading')){
            setBoreholeVerificationData(response?.data?.boreholeReading)
            setVerificationDataLoading(false)
        }
      })
      .catch(function (error) {
        console.log(error.response.message);
      });
    }

    const getBoreholeToVerify=async(id)=>{
        setLoading(true)
        await axios.get('GetVerificationBoreholeReading/'+id)
        .then(function (response) {
            if(response.data.hasOwnProperty('boreholeReading')){
                setVerificationData(response?.data?.boreholeReading)
            };
            setLoading(false)
            setShowDataModal(true);
          })
          .catch(function (error) {
            showInfo('No access rights to execute the operation');
            setLoading(false)
          });
          setReloadData(false);
    }
    React.useEffect(()=>{
        getVerificationData();
    },[])


    React.useEffect(()=>{
        if(reloadData===true){
            getVerificationData();
        }
    },[reloadData])
    React.useEffect(()=>{
        if(verifyReading !==''){
            getBoreholeToVerify(verifyReading);
        }
    },[verifyReading])

  return (
      <div className="col-md-12 col-sm-12 col-lg-12">
          <Toast ref={toast} />
          {verificationDataLoading ? (
              <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="2"
                  fill="var(--surface-ground)"
              />
          ) : (
              <div className="bg-white table-responsive">
                  <table className="table table-striped table-bordered">
                      <thead>
                          <tr>
                              <th>Borehole name</th>
                              <th>reading date</th>
                              <th>Water C.R</th>
                              <th>Water P.R</th>
                              <th>
                                  CONS (M<sup>3</sup>)
                              </th>
                              <th>Power C.R</th>
                              <th>Power P.R</th>
                              <th>Usage (kWh)</th>
                              <th>Verified</th>
                          </tr>
                      </thead>
                      <tbody>
                          {boreholeVerificationData.map((data) => (
                              <tr key={data.bhreadId}>
                                  <td>{data.borehole_name}</td>
                                  <td>{data.reading_at}</td>
                                  <td>{data.water_reading}</td>
                                  <td>{data.previous_water_reading}</td>
                                  <td>{data.water_consumed}</td>
                                  <td>{data.power_reading}</td>
                                  <td>{data.previous_power_reading}</td>
                                  <td>{data.power_consumed}</td>
                                  <td>
                                      {loading && verifyReading == data.bhreadId ? (
                                          <div
                                              className="spinner-border text-danger spinner-border-sm"
                                              role="status"
                                          >
                                              <span className="visually-hidden">
                                                  Loading...
                                              </span>
                                          </div>
                                      ) : (
                                          <>
                                              {(verifyReading == data.bhreadId) ? (
                                                  <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name='verification'
                                                      value={data.bhreadId}
                                                      onChange={(e) =>{
                                                        setVerifyReading(
                                                            e.target.value
                                                        )
                                                      }
                                                      }
                                                      defaultChecked
                                                  />
                                              ) : (
                                                  <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      name='verification'
                                                      value={data.bhreadId}
                                                      onChange={(e) =>{
                                                        setVerifyReading(
                                                            e.target.value
                                                        )
                                                      }
                                                      }
                                                  />
                                              )}
                                          </>
                                      )}
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
          )}
          <VerificationModal
              showDataModal={showDataModal}
              setShowDataModal={setShowDataModal}
              verificationData={verificationData}
              setReloadData={setReloadData}
          />
      </div>
  );
}
export default BoreholeVerification
