import React from 'react';
import ReactDOM from 'react-dom/client';
import BoreHoleComponents from './Components/BoreHoleComponents';
import OfftakeComponents from './Components/OfftakeComponents';
import TreatmentComponents from './Components/TreatmentComponents';
import ZoneRegistrationComponents from './Components/ZoneRegistrationComponents';
const Technicals = () => {

  return (
        <div>
        <div className="row justify-content-center" >
            <div className="col-sm-12 col-md-3 col-lg-3" style={{ overflowY:'scroll',maxHeight:570 }}>
                <BoreHoleComponents/>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
                <OfftakeComponents/>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3" style={{ overflowY:'scroll',maxHeight:570 }}>
                <TreatmentComponents/>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
                <ZoneRegistrationComponents/>
            </div>
        </div>{/*
        <div className="row">
            <div className="col">
            1 of 3
            </div>
            <div className="col-md-auto">
            Variable width content
            </div>
            <div className="col col-lg-2">
            3 of 3
            </div>
        </div> */}
        </div>

  )
}

export default Technicals
if (document.getElementById('technical_portals')) {
    const Index = ReactDOM.createRoot(document.getElementById("technical_portals"));
    Index.render(
        <React.StrictMode>
            <Technicals/>
        </React.StrictMode>
    )
}
