import axios from 'axios';
import React from 'react'
import ReactDOM from 'react-dom/client';
import Navigation from './Navigation';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';
import { kiambuUrl,githusubcounty,lowerLari, billingZones,customerAccountUrl, supplylineUrl, valvesUrl, meterUrl } from './Constants';
const GeoMaps = () => {
   const[dimensions,setDimensions]=React.useState({height:window.innerHeight,width:window.innerWidth});
   const[isKiambuLoading,setIsKiambuLoading]=React.useState(false);
   const[kiambuData,setKiambuData]=React.useState({});
   const [showLayers, setShowLayers] = React.useState(false);
   const [kiambuCountyLayer, setKiambuCountyLayer] = React.useState(false);
   /* coverage area */
    const[isGithsubLoading,setIsGithuSubLoading]=React.useState(false);
    const[githSubData,setGithuSubData]=React.useState({});
    const[githSubShow,setGithuSubShow]=React.useState(false);
    /* lower lari */
    const[isLariLowerLoading,setIsLariLowerLoading]=React.useState(false);
    const[lariLowerData,setLariLowerData]=React.useState({});
    const[lariLowerShow,setLariLowerShow]=React.useState(false);
   /* end coverage */
   const [billingRoutesShow,setBillingRoutesShow]=React.useState(false);
   const [billingRoutesLoading,setBillingRoutesLoading]=React.useState(false);
   const[billingRoutesData,setBillingRoutesData]=React.useState({});
   /* Billing routes */
   const [customerAccountShow,setCustomerAccountShow]=React.useState(false);
   const [customerAccountLoading,setCustomerAccountLoading]=React.useState(false);
   const [customerAccountData,setCustomerAccountData]=React.useState({});
   const[accountSearch,setAccountSearch]=React.useState([]);
   const [searchText,setSearchText]=React.useState('');
   const [disableSearch,setDisableSearch]=React.useState(true);
   const [filteredText,setFilteredText]=React.useState([]);
   const [selectedText,setSelectedaText]=React.useState({});
   /* Customer accounts */
   const [selectionData,setSelectionData]=React.useState({});
   const [selectedBasemap,setSelectedBasemap]=React.useState({id:6,name:'OpenStreet Map',url:'https://tile.openstreetmap.org/{z}/{x}/{y}.png'});
   const[homeControl,setHomeControl]=React.useState(false);
   /* Selection data */
   const[supplyShow,setSupplyShow]=React.useState(false);
   const[supplyData,setSupplyData]=React.useState({});
   const[supplyLoading,setSupplyLoading]=React.useState(false);
   /* supply line */
   const[valvesShow,setValvesShow]=React.useState(false);
   const[valvesData,setValvesData]=React.useState({});
   const[valvesLoading,setValvesLoading]=React.useState(false);
   /* valves */
   const[meterShow,setmeterShow]=React.useState(false);
   const[meterData,setmeterData]=React.useState({});
   const[meterLoading,setmeterLoading]=React.useState(false);
   /* customer meter */
   const mapRef=React.useRef();
   const highlightStyle ={
    "stroke": false,
    "fillColor": "#00FFFF",
    "fillOpacity": 0.7,
    "radius": 10,
    "weight": 2,
    "opacity": 1,
    "color": '#727272',
    "dashArray": '3'
  }
  const polyHighlight={
    color: "#03A9F4",weight:2,
     fillColor:"transparent"
   }
   const markerclustering = new L.MarkerClusterGroup({
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 15,
        maxClusterRadius:20
    })
   const toast = React.useRef(null);
   const showError = (message) => {
    toast.current.show({severity:'error', summary: 'Error', detail:message, life: 10000});
    }
    React.useEffect(()=>{
        const map = L.map('map', {
            zoominfoControl: true,
            zoomControl: false,
            zoom: 12,
            center: [-1.058634, 36.777911]
          });
          L.control.pan().addTo(map);
        mapRef.current=map;
        if(selectedBasemap.id ===6){
            const opens=L.tileLayer(selectedBasemap.url, {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);
            opens.bringToBack()
        }else{
            const sats=L.tileLayer(selectedBasemap.url, {
                maxZoom: 20,
                minZoom: 13,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);
            sats.bringToBack();
        }
        map.on("moveend", function(e){
           setHomeControl(false);
        });
        return () => {
            map.off();
            map.remove();
        }
    },[selectedBasemap])

    React.useEffect(()=>{
        if(homeControl){
            mapRef.current.setView([-1.058634, 36.777911],16)
        }
    },[homeControl])

   React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  /* Kiambu county layer */
      const getLayerData=async()=>{
        setIsKiambuLoading(true)
        await axios.get(kiambuUrl)
        .then(function (response) {
            setKiambuData(response.data);
            setIsKiambuLoading(false);
        })
        .catch(function (error) {
            const errors=JSON.stringify(error);
            showError(error.message)
            setIsKiambuLoading(false)
        })
        .then(function () {
            // always executed
        })
      }
      React.useEffect(()=>{
        if(kiambuCountyLayer===true){
            getLayerData()
        }
      },[kiambuCountyLayer])
      const kiambuCounty=L.geoJSON(null,{
        style: {
            color: "#000000",weight: 2,fillColor:"transparent",/* opacity: 1,fillOpacity: 0.7 */
        },
        onEachFeature: function (feature, layer) {
            const label = L.marker(layer.getBounds().getCenter(), {
                icon: L.divIcon({
                   className: 'label',
                   html: feature.properties.name_2,
                   iconSize: [15, 15]
                 })
                }).addTo(mapRef.current);
        }
      });
      React.useEffect(()=>{
        if(kiambuData !==undefined && Object.keys(kiambuData).length > 0){
            kiambuCounty.addData(kiambuData);
        }
      },[kiambuCountyLayer,kiambuData])

   /* Kiambu county layer */
   const getGithunguriSubCountyData=async()=>{
    setIsGithuSubLoading(true)
    await axios.get(githusubcounty)
    .then(function (response) {
        setGithuSubData(response.data);
        setIsGithuSubLoading(false);
    })
    .catch(function (error) {
        const errors=JSON.stringify(error);
        showError(error.message)
        setIsGithuSubLoading(false)
    })
    .then(function () {
        // always executed
    })
  }
  React.useEffect(()=>{
    if(githSubShow ===true){
        getGithunguriSubCountyData()
    }
  },[githSubShow])
  const githsub=L.geoJSON(null,{
    style: {
        color: "#BF360C",weight: 2,fillColor:"transparent",/* opacity: 1,fillOpacity: 0.7 */
    },
    onEachFeature: function (feature, layer) {
        if(githSubShow===true){
            layer.bindTooltip(`${feature.properties.name}  WARD` , {permanent: true,
                interactive: false , direction: 'center', className: 'zoneLabels',opacity:0.9,backgroundColor: 'black'});
        }if(githSubShow===false){
            layer.unbindTooltip();
        }
    }
  });
  React.useEffect(()=>{
    if(githSubData !==undefined && Object.keys(githSubData).length > 0){
        githsub.addData(githSubData);
    }
    if(githSubShow===true){
        mapRef.current.addLayer(githsub);
    }else{
        mapRef.current.removeLayer(githsub);
    }
    return () => {
        mapRef.current.removeLayer(githsub)
    }
  },[githSubData,githSubShow])
  /* End githunguri sub county */
  const getLowerLari=async()=>{
            setIsLariLowerLoading(true)
            await axios.get(lowerLari)
            .then(function (response) {
                setLariLowerData(response.data);
                setIsLariLowerLoading(false);
            })
            .catch(function (error) {
                const errors=JSON.stringify(error);
                showError(error.message)
                setIsLariLowerLoading(false)
            })
            .then(function () {
                // always executed
            })
        }
    React.useEffect(()=>{
        if(lariLowerShow ===true){
            getLowerLari()
        }
        },[lariLowerShow])
        const lowerLariward=L.geoJSON(null,{
            style: {
                color: "#651FFF",weight: 2,fillColor:"transparent",/* opacity: 1,fillOpacity: 0.7 */
            },
            onEachFeature: function (feature, layer) {
                if(lariLowerShow===true){
                    layer.bindTooltip(`${feature.properties.name}  WARD` , {permanent: true,
                        interactive: false , direction: 'center', className: 'zoneLabels',opacity:0.9,backgroundColor: 'black'});
                }if(lariLowerShow===false){
                    layer.unbindTooltip();
                }

            }
        });
        lowerLariward.bringToBack()
        React.useEffect(()=>{
          if(lariLowerData !==undefined && Object.keys(lariLowerData).length > 0){
            lowerLariward.addData(lariLowerData);
          }
        },[lariLowerData,lariLowerShow])
   /* Coverage area */
   const getBillingZones=async()=>{
        setBillingRoutesLoading(true)
        await axios.get(billingZones)
        .then(function (response) {
            setBillingRoutesData(response.data);
            setBillingRoutesLoading(false);
        })
        .catch(function (error) {
            const errors=JSON.stringify(error);
            showError(error.message)
            setBillingRoutesLoading(false)
        })
        .then(function () {
            // always executed
        })
    }
    React.useEffect(()=>{
        if(billingRoutesShow ===true){
            getBillingZones()
        }
    },[billingRoutesShow])
    const billingRoutes=L.geoJSON(null,{
        style: {
            color: "#FFA726",weight: 2,fillColor:"transparent",/* opacity: 1,fillOpacity: 0.7 */
        },
        onEachFeature: function (feature, layer) {
                if(billingRoutesShow===true){
                    layer.bindTooltip(feature.properties.route_name , {permanent: true,
                        interactive: false , direction: 'center', className: 'zoneLabels',opacity:0.9,backgroundColor: 'black'});
                }if(billingRoutesShow===false){
                    layer.unbindTooltip();
                }
            layer.on({
                click:(e)=>{
                    setSelectionData(e.target);
                }
            })
        }
    });
    React.useEffect(()=>{
        if(billingRoutesData !==undefined && Object.keys(billingRoutesData).length > 0){
            billingRoutes.addData(billingRoutesData);
        }
      },[billingRoutesData,billingRoutesShow])
   /* Billing zones */
   const getCustomerAccounts=async()=>{
            setCustomerAccountLoading(true)
            await axios.get(customerAccountUrl)
            .then(function (response) {
                setCustomerAccountData(response.data);
                setCustomerAccountLoading(false);
            })
            .catch(function (error) {
                const errors=JSON.stringify(error);
                showError(error.message)
                setCustomerAccountLoading(false)
            })
            .then(function () {
                // always executed
            })
        }
    React.useEffect(()=>{
            if(customerAccountShow){
                getCustomerAccounts()
            }
    },[customerAccountShow])
    const customerAccounts=L.geoJSON(null,{
        pointToLayer:function (feature, latlng){
            if(feature.properties.status==='Active'){
                return L.marker(latlng, {
                    icon: L.icon({
                      iconUrl: "images/mapicons/meters.png",
                      iconSize: [10, 10],
                      popupAnchor:  [-1, -8]
                    }),
                  });
            }
            if(feature.properties.status==="Disconnected"){
                return L.marker(latlng, {
                    icon: L.icon({
                      iconUrl: "images/mapicons/disconnected.png",
                      iconSize: [10, 10],
                      popupAnchor:  [-1, -8]
                    }),
                  });
            }
            else{
                return L.marker(latlng, {
                    icon: L.icon({
                      iconUrl: "images/mapicons/inactive-meters.png",
                      iconSize: [10, 10],
                      popupAnchor:  [-1, -8]
                    }),
                  });
            }
        },
        onEachFeature:function(feature,layer){
            setAccountSearch(prevState =>[...prevState,{
                account_nu: layer.feature.properties.account_nu,
                meter_serial:layer.feature.properties.serial_num,
                source: "Customer_accounts",
                id: L.stamp(layer),
                lat: layer.feature.geometry.coordinates[1],
                lng: layer.feature.geometry.coordinates[0]
            }])
            layer.on({
                click:(e)=>{
                    setSelectionData(e.target)
                }
            })
            layer.bindPopup(`<table class="table table-striped">
                              <tbody>
                                <tr>
                                   <td>Account Number<td>
                                   <td>${feature.properties.account_nu}<td>
                                </tr>
                                <tr>
                                    <td>Account Type<td>
                                    <td>${feature.properties.user_type}<td>
                                </tr>
                                <tr>
                                    <td>Account Status<td>
                                    <td>${feature.properties.status}<td>
                                </tr>
                                <tr>
                                    <td>Meter Serial<td>
                                    <td>${feature.properties.serial_num}<td>
                                </tr>
                              </tbody>
                            </table>`)
        }
    });
    React.useEffect(()=>{
        if(customerAccountShow && customerAccountData !==undefined && Object.keys(customerAccountData).length > 0){
            customerAccounts.clearLayers()
            markerclustering.clearLayers()
            customerAccounts.addData(customerAccountData);
            markerclustering.addLayer(customerAccounts)
        }
        if(customerAccountShow===true){
            mapRef.current.addLayer(markerclustering);
            setDisableSearch(false)
        }else{
            customerAccounts.clearLayers()
            markerclustering.clearLayers()
            mapRef.current.removeLayer(markerclustering);
            setAccountSearch([])
            setSelectionData({});
        }
        return () => {
            customerAccounts.clearLayers()
            markerclustering.clearLayers()
            mapRef.current.removeLayer(markerclustering)
        }
      },[customerAccountData,customerAccountShow])
   /* Customer accounts */
  /* Supply lines */
  const getSupplylines=async()=>{
    setSupplyLoading(true)
    await axios.get(supplylineUrl)
    .then(function (response) {
        setSupplyData(response.data);
        setSupplyLoading(false);
    })
    .catch(function (error) {
        const errors=JSON.stringify(error);
        showError(error.message)
        setSupplyLoading(false)
    })
    .then(function () {
        // always executed
    })
}
React.useEffect(()=>{
    if(supplyShow){
        getSupplylines()
    }
},[supplyShow])
const supplyLayer=L.geoJSON();
React.useEffect(()=>{
    if(supplyShow && supplyData !==undefined && Object.keys(supplyData).length > 0){
        supplyLayer.addData(supplyData);
    }
    if(supplyShow===true){
        mapRef.current.addLayer(supplyLayer);
    }else{
        mapRef.current.removeLayer(supplyLayer);
    }
    return () => {
        mapRef.current.removeLayer(supplyLayer)
    }
  },[supplyData,supplyShow])
  /* End supply lines */
  const getValves=async()=>{
    setValvesLoading(true)
    await axios.get(valvesUrl)
    .then(function (response) {
        setValvesData(response.data);
        setValvesLoading(false);
    })
    .catch(function (error) {
        const errors=JSON.stringify(error);
        showError(error.message)
        setValvesLoading(false)
    })
    .then(function () {
        // always executed
    })
}
React.useEffect(()=>{
    if(valvesShow){
        getValves()
    }
},[valvesShow])
const valvesLayer=L.geoJSON(null,{
    pointToLayer:function (feature, latlng){
            return L.marker(latlng, {
                icon: L.icon({
                  iconUrl: "images/mapicons/gate_valves.png",
                  iconSize: [10, 10],
                  popupAnchor:  [-1, -8]
                }),
              });
    }
});
React.useEffect(()=>{
    if(valvesShow && valvesData !==undefined && Object.keys(valvesData).length > 0){
        valvesLayer.addData(valvesData);
    }
    if(valvesShow===true){
        mapRef.current.addLayer(valvesLayer);
    }else{
        mapRef.current.removeLayer(valvesLayer);
    }
    return () => {
        mapRef.current.removeLayer(valvesLayer)
    }
  },[valvesData,valvesShow])
  /* network valves data */
   const getMeters=async()=>{
    setmeterLoading(true)
    await axios.get(meterUrl)
    .then(function (response) {
        setmeterData(response.data);
        setmeterLoading(false);
    })
    .catch(function (error) {
        const errors=JSON.stringify(error);
        showError(error.message)
        setmeterLoading(false)
    })
    .then(function () {
        // always executed
    })
}
React.useEffect(()=>{
    if(meterShow){
        getMeters()
    }
},[meterShow])
const metersLayer=L.geoJSON(null,{
    pointToLayer:function (feature, latlng){
            return L.marker(latlng, {
                icon: L.icon({
                  iconUrl: "images/mapicons/customer_meter.png",
                  iconSize: [15, 15],
                  popupAnchor:  [-1, -8]
                }),
              });
    },
    onEachFeature:(feature,layer)=>{
        layer.bindPopup(`<table class="table table-striped p-image">
        <tbody>
          <tr>
             <td>Serial<td>
             <td>${feature.properties.serial_num}<td>
          </tr>
          <tr>
              <td>Brand<td>
              <td>${feature.properties.brand}<td>
          </tr>
          <tr>
              <td class="text-nowrap">Size  (inch)<td>
              <td>${feature.properties.size}<td>
          </tr>
          <tr >
              <td>Condition<td>
              <td>${feature.properties.meter_cond}<td>
          </tr>
          <tr>
            <td>Class<td>
            <td>${feature.properties.meter_clas}<td>
          </tr>
         <tr>
            <td>Material<td>
            <td>${feature.properties.meter_mate}<td>
        </tr>
        <tr colSpan="4" class="p-image-preview-container">
            <td colSpan="4" class="card flex justify-content-center">
            <Image zoomSrc="meter_images/${feature.properties.image}" template='<i className="pi pi-check"></i>' src="meter_images/${feature.properties.image}" width="100%" height={100} preview/>
           </td>
        </tr>
        </tbody>
      </table>`)
    }
});
React.useEffect(()=>{
    if(meterShow && meterData !==undefined && Object.keys(meterData).length > 0){
        metersLayer.addData(meterData);
    }
    if(meterShow===true){
        mapRef.current.addLayer(metersLayer);
    }else{
        mapRef.current.removeLayer(metersLayer);
    }
    return () => {
        mapRef.current.removeLayer(metersLayer)
    }
  },[meterData,meterShow])
  /* customer meters data */

    React.useEffect(()=>{
        if(kiambuCountyLayer===true){
            mapRef.current.addLayer(kiambuCounty);
        }else{
            mapRef.current.removeLayer(kiambuCounty)
        }
        return () => {
            mapRef.current.removeLayer(kiambuCounty)
        }
    },[kiambuData,kiambuCountyLayer])
      React.useEffect(()=>{
        if(lariLowerShow===true){
            mapRef.current.addLayer(lowerLariward);
        }else{
            mapRef.current.removeLayer(lowerLariward);
        }
        return () => {
            mapRef.current.removeLayer(lowerLariward)
        }
      },[lariLowerData,lariLowerShow])
      React.useEffect(()=>{
        if(billingRoutesShow===true){
            mapRef.current.addLayer(billingRoutes);
        }else{
            mapRef.current.removeLayer(billingRoutes);
        }
        return () => {
            mapRef.current.removeLayer(billingRoutes)
            setSelectionData({});
        }
      },[billingRoutesData,billingRoutesShow])

      React.useEffect(()=>{
        if(searchText !=="" && accountSearch.length > 0){
            const results=accountSearch.filter((key) => {
                let meternumber
                const accountNumber = key.account_nu.toString()
                if (key.meter_serial !== null) {
					meternumber=key.meter_serial.toString()
				}
                return accountNumber.indexOf(searchText.toString().toUpperCase()) > -1 ||
                      meternumber?.indexOf(searchText.toString().toUpperCase()) > -1
            })
            setFilteredText(results);
        }
      },[searchText])

      React.useEffect(()=>{
        if(Object.keys(selectedText).length > 0){
            mapRef.current.setView([selectedText?.lat, selectedText?.lng], 16)
			mapRef.current?._layers[selectedText.id]?.fire('click')
        }
      },[selectedText])

      const selectionPointLayer=L.geoJSON(null,{
        pointToLayer: function (feature, latlng) {
            return L.circleMarker(latlng);
          },
        style:highlightStyle,
      });
      const selectionPolygonLayer=L.geoJSON(null,{
        style:polyHighlight
      });
      React.useEffect(()=>{
        if(Object.keys(selectionData).length > 0){
            if(selectionData.feature.geometry.type==="Point"){
                selectionPointLayer.addData(selectionData.feature);
                mapRef.current.addLayer(selectionPointLayer);
            }
            if(selectionData.feature.geometry.type==="MultiPolygon" || selectionData.feature.geometry.type==="Polygon"){
                selectionPolygonLayer.addData(selectionData.feature);
                mapRef.current.addLayer(selectionPolygonLayer);
            }
        }
        return () => {
            mapRef.current.removeLayer(selectionPointLayer)
            mapRef.current.removeLayer(selectionPolygonLayer);
        }
      },[selectionData])

  return (
    <div>
        <Toast ref={toast} />
        <Navigation
         isKiambuLoading={isKiambuLoading}
         showLayers={showLayers}
         setShowLayers={setShowLayers}
         kiambuCountyLayer={kiambuCountyLayer}
         setKiambuCountyLayer={setKiambuCountyLayer}
         disableSearch={disableSearch}
        /* sub county githunguri */
        isGithsubLoading={isGithsubLoading}
        githSubShow={githSubShow}
        setGithuSubShow={setGithuSubShow}
        /* end sub county githunguri */
        isLariLowerLoading={isLariLowerLoading}
        lariLowerShow={lariLowerShow}
        setLariLowerShow={setLariLowerShow}
        /* end lower Lari */
        billingRoutesShow={billingRoutesShow}
        setBillingRoutesShow={setBillingRoutesShow}
        billingRoutesLoading={billingRoutesLoading}
        /* customer accounts */
        customerAccountShow={customerAccountShow}
        setCustomerAccountShow={setCustomerAccountShow}
        customerAccountLoading={customerAccountLoading}
        searchText={searchText} setSearchText={setSearchText}
        filteredText={filteredText}
        setFilteredText={setFilteredText}
        setSelectedaText={setSelectedaText}
        /* selection data */
        setSelectionData={setSelectionData}
        setSelectedBasemap={setSelectedBasemap}
        setHomeControl={setHomeControl}
        /* supply line  */
        supplyShow={supplyShow}
        setSupplyShow={setSupplyShow}
        supplyLoading={supplyLoading}
        /* valves */
        valvesShow={valvesShow}
        setValvesShow={setValvesShow}
        valvesLoading={valvesLoading}
        /* meter customer */
        meterShow={meterShow}
        setmeterShow={setmeterShow}
        meterLoading={meterLoading}
        />
        <div id='map' style={{ height:dimensions.height-65,width:dimensions.width }}>
        </div>
    </div>
  )
}

export default GeoMaps
if (document.getElementById('geomap')) {
    const Index = ReactDOM.createRoot(document.getElementById("geomap"));

    Index.render(
        <React.StrictMode>
            <GeoMaps/>
        </React.StrictMode>
    )
}
