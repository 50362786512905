import React from 'react'
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
const BoreHoleReadingForm = ({boreholeData}) => {
    const toast = React.useRef(null);
    const[borehole_id,setBorehole_id]=React.useState('')
    const[water_reading,setWater_reading]=React.useState('')
    const[water_image,setWater_image]=React.useState('')
    const[power_reading,setPower_reading]=React.useState('')
    const[power_image,setPower_image]=React.useState('')
    const [reading_at,setReading_at]=React.useState('')
    const [remarks,setRemarks]=React.useState('')
    const[loading,setLoading]=React.useState(false)
    const showInfo = (msg) => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: msg });
    };
    const showError = (msg) => {
        toast.current.show({ severity:'error', summary: 'Error', detail: msg });
    };
    const showSuccess = (msg) => {
        toast.current.show({ severity:'success', summary: 'Success', detail: msg });
    };
    const saveReadings=async()=>{
        const formdatas=new FormData()
        formdatas.append('power_image',power_image)
        formdatas.append('water_image',water_image)
        formdatas.append('properties',JSON.stringify({
              powerreading:power_reading,
              waterreading:water_reading,
              readingDate:reading_at,
              readingRemarks:remarks,
              boreholeSelected:borehole_id,
            }));
            setLoading(true)
        await axios('saveBoreholeReadings',{
            method:'post',
            data: formdatas,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
            },
        }).then(function (response) {
                if (response?.data.hasOwnProperty('cycleError')) {
                    showError(response?.data?.cycleError);
                }
               if(response.data.hasOwnProperty('pastDateNtification')){
                   showError(response.data?.pastDateNtification);
               }if(response.data.hasOwnProperty('success')){
                   showSuccess(response.data?.success)
                   setPower_reading('')
                   setWater_reading('')
                   setPower_reading('')
                   setWater_reading('')
                   setReading_at('')
                   setRemarks('')
                   setBorehole_id('')
               }
               if(response.data.hasOwnProperty('ErrorSubmission')){
                 showError(response.data?.ErrorSubmission)
               }
               if (response?.data.hasOwnProperty('imagesError')) {
                showInfo(response?.data?.imagesError);
               }
               if(response.data.hasOwnProperty('recordExistence')){
                 showError(response.data?.recordExistence)
               }//
            setLoading(false)
        }).catch(function (error) {
           console.log(error.response)
           setLoading(false)
        })
        .then(function () {
        })
    }
  return (
      <>
      <Toast ref={toast} />
              <div
                    style={{
                        overflowY: "scroll",
                        maxHeight: 530,
                        padding: 10,
                        fontSize: 13,
                        fontFamily: ':"Times New Roman", Times, serif',
                    }}
                >
              <div className="mb-3">
                  <label htmlFor="borehole_id" className="form-label">
                      Select Borehole
                  </label>
                  <select
                      className="form-select col-md-12 col-sm-12 "
                      name="borehole_id"
                      value={borehole_id}
                      onChange={(e)=>setBorehole_id(e.target.value)}
                      aria-label="Default select example"
                  >
                      <option value>Select Borehole</option>
                      {boreholeData.map((data)=>(
                        <option key={data.id} value={data.id}>{data.borehole_name}</option>
                      ))}
                  </select>
              </div>
              <div className="mb-3">
                  <label htmlFor="water_reading" className="form-label">
                      Water Reading
                  </label>
                  <input
                      type="text"
                      name="water_reading"
                      value={water_reading}
                      onChange={(e)=>setWater_reading(e.target.value)}
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="water_image" className="form-label">
                      Water reading picture
                  </label>
                  <input
                      type="file"
                      name="water_image"
                      onChange={(e)=>setWater_image(e.target.files[0])}
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="power_reading" className="form-label">
                      Power reading
                  </label>
                  <input
                      type="text"
                      name="power_reading"
                      value={power_reading}
                      onChange={(e)=>setPower_reading(e.target.value)}
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="power_image" className="form-label">
                      Power reading picture
                  </label>
                  <input
                      type="file"
                      name="power_image"
                      onChange={(e)=>setPower_image(e.target.files[0])}
                      className="p-inputtext col-md-12 col-sm-12 form-control"
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="reading_at" className="form-label">
                      Reading Date
                  </label>
                  <input
                      type="date"
                      name="reading_at"
                      value={reading_at}
                      onChange={(e)=>setReading_at(e.target.value)}
                      className="p-datepicker-calendar p-inputtext col-md-12 col-sm-12 form-control"
                  />
              </div>
              <div className="mb-3">
                  <label htmlFor="remarks" className="form-label">
                      Comment
                  </label>
                  <textarea
                      className="p-inputtext col-md-12 col-sm-12"
                      name="remarks"
                      id="exampleFormControlTextarea1"
                      rows={3}
                      value={remarks}
                      onChange={(e)=>setRemarks(e.target.value)}
                  />
              </div>
                <div className="mb-3">
                    <Button label="Save Data" loading={loading} raised onClick={()=>saveReadings()} severity="info" icon="pi pi-save" />
                </div>
          </div>
      </>
  );
}

export default BoreHoleReadingForm
